import React, { useState } from 'react';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { generalInfo } from './constants';

const ShipDetailsCard = (props) => {
  const { vesselInfo, onEdit } = props;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="card mt-3 p-5 mb-3" data-testid="general-info">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="font-16 font-semibold head-black">General Information</h4>
        <div className="d-flex align-items-center">
          <button type="button" className="link-btn me-4" disabled={vesselInfo?.generalInfo?.vesselStatus === 'Inactive'} onClick={onEdit}>
            <span className="icon-edit font-16 me-2" />
            Edit
          </button>
          <span
            className={`font-12 pointer ${!isOpened ? ' icon-arrow-thin-down' : ' icon-arrow-thin-up open'}`}
            onClick={() => {
              setIsOpened(!isOpened);
            }}
          />
        </div>
      </div>
      <div className={`${!isOpened ? 'content-hide' : 'content-show vessel-info-media pt-4'}`}>
        <div className="d-flex">
          <ul className="d-flex flex-wrap col-12">
            {generalInfo.map((item) => {
              return (
                <li className="d-flex px-1 col-6 mb-2" key={item.label}>
                  <label className="col-6">{item.label}</label>
                  {item.type === 'date' ? (
                    <div className="col-6 value">
                      {vesselInfo?.generalInfo && vesselInfo?.generalInfo[item.key]
                        ? moment(vesselInfo?.generalInfo[item.key]).format('DD-MMM-YYYY')
                        : '-'}
                    </div>
                  ) : item.type === 'flag' ? (
                    <div className="d-flex col-6 value text-ellipsis pe-1">
                      <span
                        className="d-flex"
                        data-tooltip-id="project-cardimoNo"
                        data-tooltip-content={`${vesselInfo?.generalInfo && (vesselInfo?.generalInfo[item.key] || '-')}`}
                      >
                        {vesselInfo?.generalInfo?.flagUrl && (
                        <div className="port-height">
                          <img src={vesselInfo?.generalInfo?.flagUrl} alt="" className="me-1" />
                        </div>
                        )}

                        <div className={vesselInfo?.generalInfo?.flagUrl ? 'ms-1' : ''}>
                          {' '}
                          {vesselInfo?.generalInfo && vesselInfo?.generalInfo[item.key] ? _.truncate(vesselInfo?.generalInfo[item.key], { length: 15 }) : '-'}
                        </div>

                      </span>
                    </div>
                  ) : (
                    <div className="col-6 value text-ellipsis pe-1">
                      <span
                        data-tooltip-id="project-cardimoNo"
                        data-tooltip-content={`${vesselInfo?.generalInfo && vesselInfo?.generalInfo[item.key] ? vesselInfo?.generalInfo[item.key] : '-'}`}
                      >
                        {vesselInfo?.generalInfo && vesselInfo?.generalInfo[item.key] ? _.truncate(vesselInfo?.generalInfo[item.key], { length: 20 }) : '-'}
                      </span>
                    </div>
                  )}
                </li>
              );
            })}
            <Tooltip id="project-cardimoNo" place="top" className="tooltip mt-3" />
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ShipDetailsCard;
