/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { cloneDeep, size } from 'lodash';
import AddFile from './addFile';
import { getSpecAttachs, saveSpecAttachs, deleteSpecAttachs } from './services';
import { s3UploadMutiple } from '../../../../NewProject/services';
import ModalBox from '../../../../../../common/ModalBox';
import showAlert from '../../../../../../../utils/alert';
import CardWrapper from './cardWrapper';
import helper from '../../../../../../../utils/helper';
import Loader from '../../../../../../common/loader';

const acceptedFiles = ['application/pdf', 'application/msword', 'application/x-zip-compressed', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'image/tiff'];

let isSearched = false;
const Attachments = (props) => {
  const { projectSpecificationId, fromSupplementary, isTsiDisabled, isTSI, fromSpecify, projInfo,
    reloadAfterGTMT } = props;
  const { id } = useParams();
  const initialFormData = {
    fname: '',
    specId: projectSpecificationId,
    projectId: id,
    specDocument: []
  };
  const { MAX_FILE_SIZE, formValidation } = helper;
  const [showAddModal, setShowAddModal] = useState(false);
  const [allFiles, setAllFiles] = useState();
  const [formData, setFormData] = useState(initialFormData);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sLoader, setSLoder] = useState(false);

  const getProjSepcAttachs = () => {
    setSLoder(true);
    getSpecAttachs({
      projectId: id,
      specId: projectSpecificationId,
      search: isSearched ? searchText : ''
    }).then((res) => {
      setSLoder(false);
      if (res.success) {
        setAllFiles(res.data);
      }
    });
  };
  /* istanbul ignore next */
  const saveProjectSpecAttachs = () => {
    if (!formData?.specDocument?.length) {
      showAlert('Please upload at least one file', 'error');
      return;
    }
    if (formValidation(['fname'], formData)) {
      saveSpecAttachs(formData).then((res) => {
        if (res.success) {
          showAlert('Successfully Saved', 'success');
          const url = window.location.href;
          if (url.includes('tender')) {
            getProjSepcAttachs();
          } else if (url.includes('specify')) {
            reloadAfterGTMT('', projectSpecificationId, false, true);
          }
          setFormData(initialFormData);
          getProjSepcAttachs();
          setShowAddModal(false);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const deleteProjSpecAttach = (document) => {
    deleteSpecAttachs({ documentId: document, specId: projectSpecificationId }).then((res) => {
      if (res.success) {
        setAllFiles(res.data);
        showAlert('Successfully Deleted', 'success');
      }
      setIsDelete(false);
      getProjSepcAttachs();
    });
  };

  const showModal = () => {
    setShowAddModal(true);
  };

  const showDeleteModal = (document) => {
    setIsDelete(true);
    setSelectedDocument(document);
  };
  /* istanbul ignore next */
  function compressImage(file, lowerBoundKB, upperBoundKB, maxWidthOrHeight = 450, minDimension = 100, maxDimension = 900) {
    if (maxDimension - minDimension < 10) {
      return Promise.reject(new Error('Cannot achieve desired file size within dimension limits.'));
    }
    const midDimension = Math.floor((minDimension + maxDimension) / 2);
    const options = {
      maxSizeMB: upperBoundKB / 1024,
      maxWidthOrHeight: midDimension,
      useWebWorker: true
    };
    return imageCompression(file, options)
      .then((compressedFile) => {
        const fileSizeKB = compressedFile.size / 1024;
        if (fileSizeKB > upperBoundKB) {
          return compressImage(file, lowerBoundKB, upperBoundKB, midDimension, minDimension, midDimension);
        } if (fileSizeKB < lowerBoundKB) {
          return compressImage(file, lowerBoundKB, upperBoundKB, midDimension, midDimension, maxDimension);
        }
        return compressedFile;
      })
      .catch((err) => {
        console.error('Compression error:', err);
        throw err;
      });
  }
  /* istanbul ignore next */
  function isTypeNotAllowed(type) {
    const pattern = /^(?!.*(application\/x-zip-compressed|application\/pdf|application\/msword|image\/tiff|pdf|doc|docx|application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document)).*$/;
    return pattern.test(type);
  }
  /* istanbul ignore next */
  const ondrop = async (e) => {
    if (e?.target.files.length < 11) {
      let name = allFiles.specDocuments.map((items) => items.name);
      name = formData.specDocument.map((items) => items.name);
      const selectedFiles = [];
      let isDuplicate = false;

      const filePromises = Array.from(e.target.files).map(async (item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) {
          showAlert('Please upload a valid file type', 'error');
        } else if (fileSize > MAX_FILE_SIZE) {
          showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        } else if (name.indexOf(item.name) < 0) {
          if (isTypeNotAllowed(item.type) && (item.size / 1024) > 950) {
            try {
              const finalCompressedFile = await compressImage(item, 200, 950);
              const file = new File([finalCompressedFile], item.name, { type: finalCompressedFile.type });
              selectedFiles.push(file);
            } catch (err) {
              console.error('Error in compression process:', err);
            }
          } else {
            selectedFiles.push(item);
          }
        } else {
          isDuplicate = true;
        }
      });

      await Promise.all(filePromises);

      if (isDuplicate) {
        showAlert('Duplicate File(s) Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });

        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = cloneDeep(formData);
          attach.specDocument = attach.specDocument || [];

          if (!Array.isArray(attach.specDocument)) {
            attach.specDocument = [];
          }
          res.data.forEach((item, index) => {
            const nameIndex = formData.specDocument.length ? formData.specDocument.length + (index + 1) : (index + 1);
            const newItem = {
              documentId: item.documentId,
              name: item.name,
              path: item.path,
              fname: `${formData.fname}-${nameIndex}`,
              type: item.type,
              size: item.size
            };
            attach.specDocument.push(newItem);
          });
          if (attach.specDocument.length === 1) {
            attach.specDocument.map((i) => {
              i.fname = formData.fname;
            });
          } else {
            attach.specDocument.map((i, index) => {
              i.fname = `${formData.fname}-${index + 1}`;
            });
          }
          setFormData(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 10 files at a time', 'error');
    }
    e.target.value = '';
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearched = true;
      getProjSepcAttachs();
    }
  };

  useEffect(() => {
    getProjSepcAttachs();
  }, []);

  useEffect(() => {
    if (!searchText) {
      getProjSepcAttachs();
    }
  }, [searchText]);

  return (
    <div className="content-scroller-height">
      <div className="d-flex justify-content-between mt-4 " data-testid="attachs-spec">
        <div className="d-flex">
          <div className="search spec-search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
              <span
                id="search-click"
                className="icon-search"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchText}
              onKeyPress={onSearch}
              className="text-box large with-close"
              placeholder="Search by Name"
              onChange={(e) => {
                isSearched = false;
                setSearchText(e.target.value.toLowerCase());
              }}
            />
          </div>
          {isTSI ? (
            <div className="ps-4">
              <button type="button" disabled={isTsiDisabled || fromSupplementary || projInfo?.isClosed} className="btn large secondary-btn" id="add-name" onClick={() => { showModal(); }}>Add Attachment</button>
            </div>
          ) : ''}
        </div>
      </div>
      {sLoader ? <Loader /> : (
        <CardWrapper
          allFiles={allFiles}
          showDeleteModal={showDeleteModal}
          isTsiDisabled={isTsiDisabled}
          isTSI={isTSI}
          projInfo={projInfo}
        />
      )}
      {showAddModal && (
        <ModalBox
          modalClassName="medium"
          heading="Add Attachment"
          removeClass={!fromSpecify}
          onSave={() => { saveProjectSpecAttachs(); }}
          closeModal={() => {
            setShowAddModal(false);
            setFormData(initialFormData);
          }}
          buttonName="Save"
        >
          <AddFile
            formData={formData}
            ondrop={ondrop}
            setFormData={setFormData}
            initialFormData={initialFormData}
          />
        </ModalBox>
      )}
      {isDelete && (
        <ModalBox
          modalClassName="small"
          heading="Delete Attachment"
          removeClass={!fromSpecify}
          onSave={() => { deleteProjSpecAttach(selectedDocument); }}
          closeModal={() => {
            setIsDelete(false);
          }}
          buttonName="Delete"
        >
          Are you sure you want to delete the attachment?
        </ModalBox>
      )}

    </div>
  );
};
export default Attachments;
