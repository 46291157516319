/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import _ from 'lodash';

export const ratingAvg = (evlLines) => {
  let rating = 0;
  let len = 0;
  evlLines.map((item) => {
    if (item.rating) {
      len += 1;
      rating += item.rating;
      return null;
    }
  });

  return _.round(rating / len, 1);
};

export const overallAvgRating = (fullRating) => {
  let rating = 0;
  let len = 0;

  fullRating.evaluations.forEach((item) => {
    item.evaluationLines.forEach((sub) => {
      if (sub.rating) {
        len += 1;
        rating += sub.rating;
      }
    });
  });

  return len > 0 ? _.round(rating / len, 1) : 0;
};
