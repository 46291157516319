import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
// import _ from 'lodash';

const Card = (props) => {
  const { data, vesselInfo } = props;
  const [isOpened, setIsOpened] = useState(true);

  return (
    <div className="card stylecard no-shadow py-3 px-4 mb-3">
      <div className="d-flex align-items-center justify-content-between pointer" onClick={() => { setIsOpened(!isOpened); }}>
        <h4 className="head-14 link-btn">{data?.tabLabel}</h4>
        <span className={`font-10 ${!isOpened ? ' icon-arrow-thin-up' : ' icon-arrow-thin-down open'}`} />
      </div>
      <div className={`${isOpened ? 'content-hide' : 'content-show  p-4'}`}>
        <div className="row mb-2 vessel-inf align-items-end">
          {
            data?.items.map((item) => {
              return (
                <>
                  <div className="col-3 mb-1">
                    <label data-tooltip-id="project-det" data-tooltip-content={item?.label === 'VDG' ? 'Vessel Distribution Group mail-id' : ''}>{item.label}</label>
                  </div>
                  <div className="col-3 mb-1">
                    {item.type === 'date'
                      ? (
                        <div className="mb-2 value">
                          <span
                            data-tooltip-id="project-det"
                            data-tooltip-content={vesselInfo && vesselInfo[data.tabkey] && vesselInfo[data.tabkey][item.key] ? moment(vesselInfo[data.tabkey][item.key]).format('DD-MMM-YYYY') : '-'}
                          >
                            {vesselInfo && vesselInfo[data.tabkey] && vesselInfo[data.tabkey][item.key] ? moment(vesselInfo[data.tabkey][item.key]).format('DD-MMM-YYYY') : '-'}

                          </span>

                        </div>
                      ) : item.type === 'flag' ? (
                        <div className="d-flex col-6 value text-ellipsis pe-1">
                          <span
                            className="d-flex"
                            data-tooltip-id="project-det"
                            data-tooltip-content={vesselInfo?.generalInfo && (vesselInfo?.generalInfo[item.key] || '-')}
                          >
                            {vesselInfo?.generalInfo?.flagUrl && (
                              <div className="port-height">
                                <img src={vesselInfo?.generalInfo?.flagUrl} alt="" className="me-1" />
                              </div>
                            )}

                            <div className={vesselInfo?.generalInfo?.flagUrl ? 'ms-1' : ''}>
                              {' '}
                              {vesselInfo?.generalInfo && vesselInfo?.generalInfo[item.key] ? _.truncate(vesselInfo?.generalInfo[item.key], { length: 15 }) : '-'}
                            </div>

                          </span>
                        </div>
                      ) : (
                        <div className="mb-2 value text-ellipsis">
                          <span
                            data-tooltip-id="project-det"
                            data-tooltip-content={vesselInfo?.[data.tabkey]
                              && vesselInfo?.[data.tabkey][item.key]
                              ? vesselInfo[data.tabkey][item.key]
                              : '-'}
                          >
                            {vesselInfo?.[data.tabkey]
                              && vesselInfo?.[data.tabkey][item.key]
                              ? vesselInfo[data.tabkey][item.key]
                              : '-'}
                          </span>

                          {/* {_.truncate(
                            vesselInfo?.[data.tabkey] &&
                            vesselInfo?.[data.tabkey][item.key] ?
                            vesselInfo[data.tabkey][item.key] : '-',
                            {
                              length: 15,
                              separator: ' '
                            }
                          )} */}
                        </div>
                      )}
                  </div>
                </>
              );
            })
          }
        </div>
      </div>
      <Tooltip id="project-det" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Card;
