import { serviceHandler } from '../../../utils/serviceHandler';
import apiEndPoints from '../../../utils/apiUrlConstants';

const {
  GetspecCounts,
  GetProjects,
  GetSurveyData,
  getWidgets,
  DashboardGantt
} = apiEndPoints;

export const getSpecCounts = (payload) => {
  return serviceHandler({
    // url: `${GetspecCounts}?projectId=${payload.projectId}`,
    url: `${GetspecCounts}`,
    methordType: 'get',
    payload
  });
};

export const getProjectsList = (payload) => {
  return serviceHandler({
    url: `${GetProjects}`,
    methordType: 'get',
    payload
  });
};
export const getSurveyList = (payload) => {
  return serviceHandler({
    url: `${GetSurveyData}?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
    methordType: 'get',
    payload
  });
};
export const postWidget = (payload) => {
  return serviceHandler({
    url: `${getWidgets}`,
    methordType: 'post',
    payload
  });
};
export const getWidget = (payload) => {
  return serviceHandler({
    url: `${getWidgets}`,
    methordType: 'get',
    payload
  });
};
export const dashboardGantt = (payload) => {
  return serviceHandler({
    url: `${DashboardGantt}?phase=${payload.phase}&thisYearData=${payload.thisYearData}`,
    methordType: 'get',
    payload
  });
};
