/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import _ from 'lodash';
import { statusDot, jobNameMap } from './constant';
import InputType1 from '../../../../../common/InputType1';

const AddRole = (props) => {
  const { specList, addRole, setSpecList, isCompleted, supervisorUsers } = props;
  const [supervisor, setSupervisor] = useState('');
  const [customSupervisor, setCustomSupervisor] = useState('');
  const [showSupervisor, setShowSupervisor] = useState(false);
  const nullOption = { label: 'Select', value: null };
  const onRoleChange = (e) => {
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[addRole.mainKey]
      .specDataList[addRole.subKey].specAssignedStaff = e.value;
    currentSpec.specQuoteList[addRole.mainKey]
      .specDataList[addRole.subKey].specAssignedStaffName = e.label;
    setSpecList(currentSpec);
    setCustomSupervisor('');
    setSupervisor(e);
    setShowSupervisor(false);
  };
  const onNewSupervisorAdd = (e) => {
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[addRole.mainKey]
      .specDataList[addRole.subKey].specAssignedStaff = e.target.value;
    currentSpec.specQuoteList[addRole.mainKey]
      .specDataList[addRole.subKey].specAssignedStaffName = e.target.value;
    setSpecList(currentSpec);
    setSupervisor(nullOption);
    setCustomSupervisor(e.target.value);
  };
  const clearCustomSupervisor = () => {
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[addRole.mainKey]
      .specDataList[addRole.subKey].specAssignedStaff = '';
    currentSpec.specQuoteList[addRole.mainKey]
      .specDataList[addRole.subKey].specAssignedStaffName = '';
    setSpecList(currentSpec);
    setCustomSupervisor('');
  };
  useEffect(() => {
    const currentSpec = { ...specList };
    const specData = currentSpec.specQuoteList[addRole.mainKey].specDataList[addRole.subKey];
    if (specData.specAssignedStaff && specData.specAssignedStaffName
      && (specData.specAssignedStaff === specData.specAssignedStaffName)) {
      setCustomSupervisor(specData.specAssignedStaff);
      setShowSupervisor(true);
    } else {
      setSupervisor(!(specData.specAssignedStaffName || specData.specAssignedStaff) ? nullOption
        : { label: specData.specAssignedStaffName, value: specData.specAssignedStaff });
      setShowSupervisor(false);
    }
  }, []);
  const specData = specList.specQuoteList[addRole.mainKey].specDataList[addRole.subKey];
  return (
    <div>
      <div className="d-flex bg-sky-white align-items-center p-3 justify-content-between">
        <div className="d-flex align-items-center">
          <div className="d-flex head-14 ">{specList.specQuoteList[addRole.mainKey].specDataList[addRole.subKey].specificationNo}</div>
          <div className="  ms-4 head-14">
            <span
              data-tooltip-id="project-phrep"
              data-tooltip-content={specList.specQuoteList[addRole.mainKey]
                .specDataList[addRole.subKey].title}
            >
              {_.truncate(specList.specQuoteList[addRole.mainKey]
                .specDataList[addRole.subKey].title, { length: 20 })}
            </span>

          </div>
          <span className="icon-woha ms-3" />
          <div className="couter-bubble p-2 ms-3 width-fit bg-white">{`${specData.workOrderClosedCount}/${specData.workOrderTotalCount}`}</div>
        </div>
        <div className="d-flex align-items-center ">
          {statusDot(specData.jobStatus)}
          {jobNameMap[specData.jobStatus]}
        </div>
      </div>
      <div className="mt-3">
        <div className="opacity-6 mb-2">Name</div>
        <Select
          className="modal-select"
          id="rolename"
          name="supervisor"
          isDisabled={isCompleted}
          options={[nullOption, ...supervisorUsers]}
          onChange={(e) => onRoleChange(e)}
          value={(!customSupervisor && supervisor?.value) ? supervisorUsers?.filter((roleItem) => roleItem.value === specData.specAssignedStaff)[0] : nullOption}
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <div className="col-1">
          {!showSupervisor && (
            <button
              type="button"
              className="secondary-btn large"
              onClick={() => {
                setShowSupervisor(true);
              }}
            >
              Add
            </button>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        {showSupervisor && (
          <div className="col-12 relative-position">
            <InputType1
              id="customRoleName"
              name="customSupervisor"
              label="Name, Rank"
              maxLength="30"
              className="modal-input"
              value={!supervisor?.value ? customSupervisor : ''}
              onChange={(e) => { onNewSupervisorAdd(e); }}
            />
            {customSupervisor && (
              <span
                className="icon-close font-20 red mt-2 close-custom-supervisor"
                onClick={() => {
                  clearCustomSupervisor();
                }}
              />
            )}
          </div>
        )}
      </div>
      <Tooltip id="project-phrep" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default AddRole;
