import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getPurchaseOrderRequestsBySpec, saveSpecPurchaseOrder, deletePopr } from './services';
import { getAcCode } from '../ServiceLine/services';
import CardWrapper from './cardWrapper';
import AddTag from './addTag';
import helper from '../../../../../../../utils/helper';
import ModalBox from '../../../../../../common/ModalBox';
import showAlert from '../../../../../../../utils/alert';
import UpdatePo from './updatePO';
import { getCurrencyList } from '../../../../NewProject/services';

const POPR = (props) => {
  const { projectSpecificationId, onReload, isTsiDisabled, currencyId, fromSupplementary,
    activeTabSupplementary, isTSI, currency, fromVariation, fromSpecify, fullHeight = false,
    projInfo, reloadAfterGTMT, projectData } = props;
  const { formValidation, ZERO_UUID, roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const managementId = localStorage.getItem('managementId');
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const initialValue = {
    id: '',
    prPoNo: '',
    prPoType: 0,
    purchaseType: '',
    acCodeId: ZERO_UUID,
    acCode: '',
    orderDate: '',
    specId: '',
    category: '',
    description: '',
    status: '',
    currencyId: '',
    amount: ''
  };
  let isSearchUpdated = false;
  const [allPurchaseOrder, setAllPurchaseOrder] = useState([]);
  const [projectCurrency, setProjectCurrency] = useState();
  const { generateOptions } = helper;
  const [purchaseOrder, setPurchaseOrder] = useState(initialValue);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [acodeList, setAcCodeList] = useState([]);
  const [OpenEditModal, setOpenEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [editId, setEditId] = useState('');
  const [visibleSpec, setVisibleSpec] = useState(['0', '1']);
  const [newACCode, setnewACCode] = useState(false);
  const changeVisibility = (key) => {
    const visibility = [...visibleSpec];

    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }

    setVisibleSpec(visibility);
  };

  const checkType = () => {
    if (visibleSpec.length === 2) {
      return 3;
    } if (visibleSpec.length === 0) {
      return 0;
    } if (visibleSpec[0] === '0') {
      return 2;
    }
    return 1;
  };

  const getPurchaseOrder = () => {
    getPurchaseOrderRequestsBySpec({
      specId: projectSpecificationId,
      search: isSearchUpdated ? searchText : '',
      prPoType: checkType()
    }).then((res) => {
      if (res.success) {
        setAllPurchaseOrder(res.data);
        setLoading(false);
      }
    });
  };
  const getProjectCurrencyList = () => {
    getCurrencyList().then((res) => {
      if (res.data) {
        setProjectCurrency(generateOptions(res?.data, 'id', 'currencyName'));
      }
    });
  };
  const deletePo = (item) => {
    setDeleteModal(true);
    setDeleteId(item);
  };

  const deletePurchaseOrder = () => {
    deletePopr({ specId: projectSpecificationId, id: deleteId.id, poprType: deleteId.prPoType })
      .then((res) => {
        if (res.success) {
          showAlert('Successfully Deleted', 'success');
          setDeleteModal(false);
          getPurchaseOrder();
          onReload();
        }
      });
  };

  /* istanbul ignore next */

  const savePurchaseOrderDetails = () => {
    purchaseOrder.orderDate = moment();
    purchaseOrder.prPoType = purchaseOrder.prPoType === 0 ? 2 : purchaseOrder.prPoType;
    purchaseOrder.id = purchaseOrder.id || ZERO_UUID;
    setEditId('');
    purchaseOrder.specId = projectSpecificationId;
    purchaseOrder.acCodeId = isVesselUser && !purchaseOrder.acCodeId
      ? ZERO_UUID : purchaseOrder.acCodeId ? purchaseOrder.acCodeId : ZERO_UUID;
    let validate = [];

    if (isVesselUser) {
      validate = ['prPoNo', 'status', 'description', 'currencyId', 'amount'];
    } else {
      validate = ['prPoNo', 'status', 'description', 'currencyId', 'amount'];
    }
    if (formValidation(validate, purchaseOrder)) {
      saveSpecPurchaseOrder(purchaseOrder).then((res) => {
        if (res.success) {
          if (purchaseOrder.id && purchaseOrder.id !== ZERO_UUID) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
          setPurchaseOrder(initialValue);
          getPurchaseOrder();
          setOpenModal(false);
          setOpenEditModal(false);
          setnewACCode(false);
          onReload();
          const url = window.location.href;
          if (url.includes('specify')) {
            reloadAfterGTMT('', projectSpecificationId, false, true);
          }
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const getAcCodeList = () => {
    getAcCode({
      page: 1,
      pageSize: 1000,
      managementGroupId: managementId || ZERO_UUID
    }).then((res) => {
      if (res.success) {
        setAcCodeList(generateOptions(res.data.items, 'id', 'name'));
      }
    });
  };

  const editData = (data) => {
    const inputData = { ...data,
      acCodeId: data.acCodeId === ZERO_UUID
        ? ZERO_UUID : data.acCodeId };
    setPurchaseOrder(inputData);
    setEditId(data.id);
    setOpenEditModal(true);
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearchUpdated = true;
      getPurchaseOrder();
    }
  };

  const openAddModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setPurchaseOrder(initialValue);
  };

  useEffect(() => {
    getAcCodeList();
  }, [managementId]);

  useEffect(() => {
    getPurchaseOrder();
    getProjectCurrencyList();
    setLoading(true);
  }, []);

  useEffect(() => {
    getPurchaseOrder();
  }, [visibleSpec]);

  useEffect(() => {
    if (!searchText) {
      getPurchaseOrder();
    }
  }, [searchText]);

  return (
    <div data-testid="popr" className={fullHeight ? 'mb-5' : 'mb-5 content-scroller-height'}>
      <div className="d-flex justify-content-between mt-4 ">
        <div className="d-flex">
          <div className="search large spec-search w-full">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('searchs')?.focus(); }}
              />
              <span
                className="icon-search"
                id="search"
                onClick={() => { onSearch({ key: 'Enter' }); }}
              />
            </div>
            <input
              type="text"
              id="searchs"
              className="text-box large with-close"
              placeholder="Search by Type, PO No, PR No, Category"
              value={searchText}
              onKeyPress={onSearch}
              onChange={(e) => {
                setSearchText(e.target.value);
                isSearchUpdated = false;
              }}
            />
          </div>
          <div className="ps-4">
            {isTSI && !isVesselUser
              ? <button disabled={isTsiDisabled || (fromSupplementary && !activeTabSupplementary) || projInfo?.isClosed || projectData?.isClosed} type="button" id="add-new" className="btn secondary-btn whitespace-pre large" onClick={() => { openAddModal(); }}>Add PO</button>
              : ''}
            {openModal && (
              <ModalBox
                modalClassName="medium"
                heading="Add PO"
                removeClass={!fromSpecify}
                onSave={() => { savePurchaseOrderDetails(); }}
                closeModal={() => {
                  onCloseModal();
                  setnewACCode(false);
                }}
                buttonName="Save"
              >
                <AddTag
                  purchaseOrder={purchaseOrder}
                  acodeList={acodeList}
                  setPurchaseOrder={setPurchaseOrder}
                  currency={currency}
                  projectCurrency={projectCurrency}
                  currencyId={currencyId}
                  isVesselUser={isVesselUser}
                  newACCode={newACCode}
                  setnewACCode={setnewACCode}
                  projectData={projectData}
                />
              </ModalBox>
            )}
            {OpenEditModal && (
            <ModalBox
              modalClassName="medium"
              heading="Update PO"
              removeClass={!fromSpecify}
              onSave={() => { savePurchaseOrderDetails(); }}
              closeModal={() => {
                setOpenEditModal(false);
                setPurchaseOrder(initialValue);
                setEditId('');
                setnewACCode(false);
              }}
              buttonName="Update"
            >
              <UpdatePo
                purchaseOrder={purchaseOrder}
                acodeList={acodeList}
                setPurchaseOrder={setPurchaseOrder}
                currency={currency}
                projectCurrency={projectCurrency}
                currencyId={currencyId}
                isVesselUser={isVesselUser}
                newACCode={newACCode}
                setnewACCode={setnewACCode}
                projectData={projectData}
              />
            </ModalBox>
            )}
          </div>
          <div className="ms-5 mt-2 check-box w-100">
            <input type="checkbox" id="purchase_order" className="whitespace-pre" checked={visibleSpec.indexOf('0') > -1} onClick={() => { changeVisibility('0'); }} />
            <label title="Purchase Order" htmlFor="purchase_order">PO</label>
          </div>
          <div className="ms-5 mt-2 check-box w-100">
            <input type="checkbox" id="request" classsName="whitespace-pre" checked={visibleSpec.indexOf('1') > -1} onClick={() => { changeVisibility('1'); }} />
            <label title="Purchase Request" htmlFor="request">PR</label>
          </div>
        </div>
      </div>
      <div className="">
        <CardWrapper
          allPurchaseOrder={allPurchaseOrder}
          editData={editData}
          deletePo={deletePo}
          loading={loading}
          isTsiDisabled={isTsiDisabled}
          isTSI={isTSI}
          currency={currency}
          fromVariation={fromVariation}
          fromSupplementary={fromSupplementary}
          activeTabSupplementary={activeTabSupplementary}
          projInfo={projInfo}
          isVesselUser={isVesselUser}
          projectData={projectData}
        />
      </div>
      {deleteModal && (
        <ModalBox
          openDeleteModal={deleteModal}
          modalClassName="medium"
          heading="Delete PO"
          removeClass={!fromSpecify}
          onSave={() => { deletePurchaseOrder(); }}
          closeModal={() => { setDeleteModal(false); }}
          buttonName="Delete"
          id="delete-po"
        >
          <p> Are you sure you want to delete the PO?</p>
        </ModalBox>
      )}
    </div>
  );
};
export default POPR;
