import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import ModalBox from '../../../../../common/ModalBox';
import { saveSectionDetails } from '../services';
import { pieChartConfig } from '../SpecComponents/constant';
import showAlert from '../../../../../../utils/alert';

const SpecReason = ({ specList, mainKey, subKey, reload, reasons, projectBasicDetails }) => {
  const bottomRef = useRef(null);

  const [showComment, setShowComment] = useState(false);
  const [reasonsList, setReasonsList] = useState([]);
  const [specifiList, setSpecifiList] = useState('');
  const [newComment, setNewComment] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  /* istanbul ignore next */
  const save = () => {
    const v = String(newComment)?.trim();
    if (!v || v === null || v === '' || v === ' ') {
      showAlert('Please enter valid comments', 'error');
    } else {
      const currentSpec = { ...specifiList };
      const i = currentSpec.specQuoteList[mainKey].specDataList[subKey].statusCommentList || [];
      i.push({ comment: newComment, type: 1 });
      currentSpec.specQuoteList[mainKey].specDataList[subKey].statusCommentList = i;
      saveSectionDetails(currentSpec.specQuoteList[mainKey]).then(() => {
        reload();
        setNewComment('');
      });
    }
  };
  useEffect(() => {
    setReasonsList(reasons);
    setSpecifiList(specList);
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [reasons]);

  const messagesEndRef = useRef(null);
  /* istanbul ignore next */
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (showComment) scrollToBottom();
  }, [reasons, showComment]);
  /* istanbul ignore next */
  const getColor = (s) => {
    const sList = cloneDeep(pieChartConfig);
    const f = sList.filter((i) => i.label === s);
    if (f.length) return f[0].color;
    return 'fade-color';
  };
  return (
    <div className={openDeleteModal ? '' : 'spec-comment'} data-testid="SpecReason-main">
      <div className="d-flex relative">
        {reasonsList && reasonsList.length > 0 && <span className="dot-indicator" />}
        <button
          type="button"
          className=""
          id="comments"
          onClick={() => {
            setShowComment(true);
          }}
        >
          <span className="icon-message-square font-22 brand-color ms-2" />
        </button>
      </div>
      {showComment && (
        <ModalBox
          closeModal={() => {
            if (newComment?.trim()) {
              setOpenDeleteModal(true);
              setShowComment(false);
            } else {
              setShowComment(false);
              setNewComment('');
            }
          }}
          heading="Comments"
          modalClassName=" medium"
        >
          <>
            {reasonsList && reasonsList.length > 0 ? (
              <>
                <div className="scroll-wrap px-4 h-spec-comment">
                  {reasonsList.map((comment, index) => {
                    const commentArray = (comment && comment.comment) ? comment.comment.split('\n') : [];
                    return (
                      <div className="w-full pb-4" id={index}>
                        <div className="row">
                          <div className="col-1 d-flex justify-content-center">
                            <span className="icon-message-square font-28 opacity-6 brand-color rig-20" />
                          </div>
                          <div className="col-11">
                            <div className="bg-white p-4 w-full card-table">
                              <div className="d-flex text-black pb-3 col-gap-1 border-down">
                                <p className="">
                                  {comment.userName}
                                </p>
                              </div>
                              <div className="pt-3" />
                              {comment.type === 0 ? (
                                <div className="align-items-center d-flex font-semibold mb-2">
                                  Status changed to
                                  <span className="icon-disc ms-2 me-1" style={{ color: getColor(comment.statusName) }} />
                                  {comment.statusName}
                                  {' '}
                                  !
                                </div>
                              ) : ''}
                              {commentArray.map((data) => (
                                <p className="break-all">{data}</p>
                              ))}
                            </div>
                            <div className="mt-3 ps-1 font-12">
                              {comment.commentDate ? moment.utc(comment.commentDate.split('.')[0]).format('DD-MMM-YYYY HH:mm') : ''}
                              {' '}
                              (UTC)
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div />
            )}
            <div ref={bottomRef} />
            <div ref={messagesEndRef} />
            <div className="modal-footer bg-white p-4">
              <label className="mb-1">Add your comments</label>
              <textarea
                id="reason"
                onChange={(e) => {
                  setNewComment(e.target.value);
                }}
                type="text"
                name="reason"
                value={newComment}
                className="text-box w-full h-text-area p-2  "
              >
                {newComment}
              </textarea>
              <div className="d-flex justify-content-end">
                <button
                  disabled={(!newComment) || projectBasicDetails?.isClosed}
                  className="primary-btn"
                  onClick={() => save()}
                  type="button"
                  id="saveReason"
                >
                  Save
                </button>
              </div>
            </div>
          </>
        </ModalBox>
      )}
      {openDeleteModal ? (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          heading="Close"
          onSave={() => {
            setShowComment(false);
            setNewComment('');
            setOpenDeleteModal(false);
          }}
          closeModal={() => {
            setShowComment(true);
            setOpenDeleteModal(false);
          }}
          buttonName="Yes"
          isNoLabel="No"
        >
          <p>Are you sure you want to close ?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};
export default SpecReason;
