import React from 'react';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../common/InputType1';

const EditFieldWrapper = (props) => {
  const { formData, userData, icons, edit, field, handleFormData, handleChange } = props;
  return (
    <>
      {field === 'profileName' && edit.profileName && (
        <div className="col-12 d-flex align-items-center gap-2">
          <InputType1
            className={`${(!formData?.firstName) ? 'error-field' : ''} modal-input col-12`}
            name="firstName"
            value={formData?.firstName}
            isMandatory="true"
            label="First Name"
            onChange={(e) => {
              handleFormData({
                ...formData,
                firstName: e.target.value
              });
            }}
            id="firstName"
          />
          <InputType1
            className="modal-input col-12"
            name="middleName"
            value={formData?.middleName}
            label="Middle Name"
            onChange={(e) => {
              handleFormData({
                ...formData,
                middleName: e.target.value
              });
            }}
            id="middleName"
          />
          <InputType1
            className="modal-input col-12"
            name="lastName"
            value={formData?.lastName}
            label="Last Name"
            onChange={(e) => {
              handleFormData({
                ...formData,
                lastName: e.target.value
              });
            }}
            id="lastName"
          />
          <span
            disabled={
              ((!formData?.firstName) || ((formData?.firstName === userData?.firstName)
                && (formData?.middleName === userData?.middleName)
                && (formData?.lastName === userData?.lastName)))
            }
            className={`icon-check-mark font-20 me-3 mt-5 check-green 
                              ${((!formData?.firstName) || ((formData?.firstName === userData?.firstName)
                && (formData?.middleName === userData?.middleName)
                && (formData?.lastName === userData?.lastName)))
              ? 'disabled pointer-event-none' : 'pointer'}`}
            data-tooltip-id="edit-profile"
            data-tooltip-content="Save"
            onClick={() => handleChange('profileName', 'save')}
          />
          <span className="icon-cancel font-20 me-3 mt-5 pointer close-red" data-tooltip-id="edit-profile" data-tooltip-content="Cancel" onClick={() => handleChange('profileName', 'cancel')} />
        </div>
      )}
      {field !== 'profileName' && !edit[field] && (
        <div className="d-flex gap-3 col-1">
          <span className={`me-3 col-4 align-items-center ${icons} ${field === 'phone' ? 'font-20' : 'font-24'}`} />
          <span>{formData[field]}</span>
          <span className="icon-edit font-16 ms-3 pointer pt-2" data-tooltip-id="edit-profile" data-tooltip-content="Edit" onClick={() => handleChange(field, 'edit')} />
        </div>
      )}
      {field !== 'profileName' && edit[field] && (
        <div className="col-6 d-flex align-items-center gap-3">
          <span className={`font-26 ${field === 'phone' ? 'me-2' : 'me-4'} ${icons}`} />
          <InputType1
            className="modal-input me-3 col-12"
            name={field}
            value={formData[field]}
            onChange={(e) => {
              handleFormData({
                ...formData
              }, field, e);
            }}
            id={field}
          />
          <span
            disabled={formData[field] === userData[field]}
            data-tooltip-id="edit-profile"
            data-tooltip-content="Save"
            className={`icon-check-mark font-20 me-3 check-green 
                              ${formData[field] === userData[field] ? 'disabled pointer-event-none' : 'pointer'}`}
            onClick={() => handleChange(field, 'save')}
          />
          <span className="icon-cancel font-16 me-3 pointer close-red" data-tooltip-id="edit-profile" data-tooltip-content="Cancel" onClick={() => handleChange(field, 'cancel')} />
        </div>
      )}
      <Tooltip id="edit-profile" place="top" className="tooltip mt-3" />
    </>
  );
};

export default EditFieldWrapper;
