/* istanbul ignore file */
/* eslint-disable radix */
/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useOutletContext, useParams } from 'react-router-dom';
import qs from 'query-string';
import ModalBox from '../../../../common/ModalBox';
import JobCard from './Children/JobCard';
import NavBar from './Children/NavBar';
import ProjectCard from './Children/ProjectCard';
import Specifications from './Children/Specifications';
import {
  getAllSpecList,
  saveSectionDetails,
  repairSpecUsers,
  projectSpecStatusData,
  getProjectRepairData,
  NotCompletedTasksDetails,
  getSupervisorUsers,
  saveSubSpecProgressBar
} from './services';
import AddAssignee from './SpecComponents/addAssignee';
import AddRole from './SpecComponents/addRole';
import helper from '../../../../../utils/helper';
import { cloneDeep, debounce } from 'lodash';
import NonSpecifications from './NonSpecItem';
import StatusConfirm from './Children2/statusConfirm';
import { getProjectDetailsTSI } from '../../NewProject/services';
import { updateSpecServiceLines, getPublishUomList, getAcCode } from '../Specify/SpecsList/ServiceLine/services';
import AddService from '../Specify/SpecsList/ServiceLine/Children/addService';
import StartRepair from './Children/StartRepair';
import TaskModal from './TaskModal';
import { GetFuelData } from '../../../../Admin/DataConfig/GeneralConfig/services';
import showAlert from '../../../../../utils/alert';

const Repair = () => {
  const shouldLog = useRef(true);
  const { id } = useParams();
  const vesselId = localStorage.getItem('vesselId');
  const params = qs.parse(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [specList, setSpecList] = useState({});
  const [originalSpecList, setOriginalSpecList] = useState({});
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [addAssignee, setAddAssignee] = useState(false);
  const [addRole, setAddRole] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [assigneeList, setAssigneeList] = useState([]);
  const [statusData, setStatusData] = useState({});
  const [active, setActive] = useState('spec');
  const [visibleSpec, setVisibleSpec] = useState([1, 2]);
  const [projectCurrecySymbol, setProjectCurrencySymbol] = useState('');
  const [searchTexts, setSearchTexts] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  const [startRepairClicked, setStartRepairClicked] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [openTaskModal, setopenTaskModal] = useState(true);
  const [supervisorUsers, setSupervisorUsers] = useState([]);
  const [jobStatus, setJobStatus] = useState('');
  const { generateOptions, roles, formValidation, ZERO_UUID, addColor } = helper;
  const [openModal, setOpenModal] = useState(false);
  const [uomList, setUomList] = useState([]);
  const [acCodeList, setAcCodeList] = useState([]);
  const [newACCode, setnewACCode] = useState(false);
  const [serviceData, setServiceData] = useState('');
 
  const {
    showView,
    projInfo,
    closeSpec: [closeSpecId, setCloseSpecId],
    repairStart: [projectRepair, setProjectRepair],
  } = useOutletContext() || {
    showView: true,
    projInfo: {},
    closeSpec: [],
    repairStart: []
  };

  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR || userRole === roles?.ADMIN;
  const isVesselUser = userRole === roles.VESSEL_USER;
  const managementId = localStorage.getItem('managementId');

  const reloadData = () => {
    getPublishUomList().then((res) => {
      if (res.success) {
        setUomList(generateOptions(res.data, 'uomId', 'uomName'));
      }
    });
    getAcCode({
      page: 1,
      pageSize: 1000,
      managementGroupId: managementId || ZERO_UUID
    }).then((resp) => {
      if (resp.success) {
        setAcCodeList(generateOptions(resp.data.items, 'id', 'name', 'code'));
      }
    });
  };

  useEffect(() => {
    reloadData();
  }, [managementId]);

  const saveApiCall = () => {
    const i = cloneDeep(serviceData);
    i.acCodeId = i.acCodeId ? i.acCodeId : ZERO_UUID;
    if (i.acCode && !i.acCodeId) {
      i.acCodeId = ZERO_UUID;
    }
    i.ParentSpecId = showsubSec;
    if (formValidation(['uomId', 'serviceLineName'], i)) {
      updateSpecServiceLines(i).then((res) => {
        if (res.success) {
          showAlert('Successfully Updated', 'success');
          setnewACCode(false);
          getSpecListData();
        }
      });
      setOpenModal(false);
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const getSpecListData = () => {
    setIsLoading(true);
    getAllSpecList({ id, type: searchTexts, jobStatus }).then((resp) => {
      if (resp.success) {
        setSpecList(JSON.parse(JSON.stringify(resp.data)));
        setOriginalSpecList(JSON.parse(JSON.stringify(resp.data)));
      }
      setIsLoading(false);
    });
  };
  const getPieStatus = () => {
    projectSpecStatusData({ projectId: id }).then((res) => {
      if (res.success) {
        setStatusData(res.data);
      }
    });
  };
  const updateRepair = () => {
    getProjectRepairData(id).then((res) => {
      setProjectRepair(res.data.isRepairStarted);
    });
  };
  const SupervisorUsers = () => {
    getSupervisorUsers(params.vesselId).then((res) => {
      const options = res.data?.map((opt) => {
        return ({ label: opt.userName + ' (' + opt.roleName + ')', value: opt.userId });
      });
      setSupervisorUsers(options);
    });
  };
  useEffect(() => {
    if (shouldLog.current) {
      getSpecListData();
      getPieStatus();
      SupervisorUsers();
      repairSpecUsers({ id: params.vesselId }).then((res) => {
        if (res.success) {
          setAssigneeList(generateOptions(res.data, 'id', 'firstName'));
        }
      });
      shouldLog.current = false;
      updateRepair();
    }
  }, []);

  const setReloadAfterStart = () => {
    updateRepair();
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getSpecListData();
    }
  };
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  useEffect(() => {
    if (closeSpecId) {
      setActive('spec');
      setMainSec(closeSpecId.parentSpecificationId);
      setShowSubSec('');
      setTimeout(() => {
        const element = document.getElementById(closeSpecId.id);
        if (element) {
          element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
        setCloseSpecId('');
      }, 20);
    }
  }, [closeSpecId]);
  const showUpdateAssignee = (mainKey, subKey, isNew, oldValue) => {
    setAddAssignee({
      mainKey,
      subKey,
      isNew,
      oldValue
    });
  };
  const showUpdateRole = (mainKey, subKey, isNew) => {
    setAddRole({
      mainKey,
      subKey,
      isNew
    });
  };
  const onReloadSpecData = () => {
    getSpecListData();
    getProjectDetailsTSI({ id }).then((res) => {
      setProjectBasicDetails(res.data);
    });
  };
  const onSaveSection = (key) => {
    saveSectionDetails(specList.specQuoteList[key]).then((res) => {
      if (res.success) {
        onReloadSpecData();
      }
    });
  };
  const onSaveAssignee = () => {
    onSaveSection(addAssignee.mainKey);
    setAddAssignee(false);
  };
  const supervisorValidation = (field) => {
    const regex = /^([^,]*[a-zA-Z0-9 ]+[^,]*[\,]{1}[^,]*[a-zA-Z0-9 ]+[^,]*)$/s;
    const el = document.getElementById(field);
    if (el) {
      if (!el.value || el.value?.match(regex)?.length) {
        addColor(field, '');
      } else {
        addColor(field, 'add');
        return false;
      }
    }
    return true;
  }
  const onSaveRole = () => {
    if (supervisorValidation('customRoleName', specList.specQuoteList[addRole.mainKey].specDataList[addRole.subKey])) {
      onSaveSection(addRole.mainKey);
      setAddRole(false);
    } else {
      showAlert('Please enter Supervisor, Role', 'error');
    }
  };
  const onSpecStatusChange = (e, key, subKey, reason) => {
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[key].specDataList[subKey].jobStatus = e.value;
    currentSpec.specQuoteList[key].specDataList[subKey].jobStatusName = e.label;
    currentSpec.specQuoteList[key].specDataList[subKey].jobStatusComment = reason;
    currentSpec.specQuoteList[key].specDataList[subKey].specProgressBarValue = e.value === 4
      ? 100 : currentSpec.specQuoteList[key].specDataList[subKey].specProgressBarValue;
    const i = currentSpec.specQuoteList[key].specDataList[subKey].statusCommentList || [];
    if (reason) {
      i.push({ comment: reason, type: 0, status: e.value, statusName: e.label });
    }
    currentSpec.specQuoteList[key].specDataList[subKey].statusCommentList = i;
    saveSectionDetails(currentSpec.specQuoteList[key]).then((res) => {
      if (res.success) {
        getPieStatus();
        setSpecList(currentSpec);
        getSpecListData();
      } else {
        onReloadSpecData();
      }
    });
    setShowConfirm(false);
  };
  const specChangeSelector = (e, key, subKey) => {
    const confirmIds = [4, 6, 3, 2, 5];
    if (confirmIds.indexOf(e.value) > -1) {
      setShowConfirm({ e, key, subKey });
    } else {
      onSpecStatusChange(e, key, subKey, '');
    }
  };
  const onDateUpDate = (e, mainIndex, subIndex) => {
    const currentSpec = { ...specList };
    if (e.startDate) {
      currentSpec.specQuoteList[mainIndex].specDataList[subIndex].startDate = `${moment(e.startDate).format('YYYY-MM-DDTHH:mm:ss')}Z`;
    }
    if (e.endDate) {
      currentSpec.specQuoteList[mainIndex].specDataList[subIndex].endDate = `${moment(e.endDate).format('YYYY-MM-DDTHH:mm:ss')}Z`;
    }
    saveSectionDetails(currentSpec.specQuoteList[mainIndex]);
    setSpecList(currentSpec);
  };
  const updateCost = () => { };
  const handleCancel = (status, mainIndex, subIndex, num) => {
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[mainIndex].specDataList[subIndex].serviceLineList[num].jobStatus = status;
    saveSectionDetails(currentSpec.specQuoteList[mainIndex]);
    setSpecList(currentSpec);
  };
  const saveProgressRef = useRef(
    debounce((projectSpecId, value) => {
      saveSubSpecProgressBar(projectSpecId, value);
    }, 500)
  ).current;
 
  useEffect(() => {
    return () => {
      saveProgressRef.cancel();
    };
  }, []);
 
  const onProgresChange = (e, mainIndex, subIndex, projectSpecId) => {
    const value = parseInt(e.target.value);   
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[mainIndex].specDataList[subIndex].specProgressBarValue = value;   
    if (currentSpec.specQuoteList[mainIndex].specDataList[subIndex].jobStatus === 0) {
      currentSpec.specQuoteList[mainIndex].specDataList[subIndex].jobStatus = 1;
      currentSpec.specQuoteList[mainIndex].specDataList[subIndex].jobStatusName = 'In Progress';
    }   
    setSpecList(currentSpec);   
    saveProgressRef(projectSpecId, value);
  };
  useEffect(() => {
    if (!showView) {
      getSpecListData();
    }
  }, [showView]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectDetailsTSI({ id }).then((res) => {
      setProjectBasicDetails(res.data);
      localStorage.setItem('managementId', res?.data?.managementId);
      setProjectCurrencySymbol(res?.data?.currencySymbol);
    });
  }, []);
  const [tableData, setTableData] = useState([]);
  const fuelData = () => {
    GetFuelData({}).then((res) => {
      setTableData(res.data);
    });
  };
  const [fuelPriceType1Value, setFuelPriceType1Value] = useState({});
  const [fuelPriceType2Value, setFuelPriceType2Value] = useState({});

  const options = tableData?.map((opt) => {
    return ({ label: opt.name, value: opt.id });
  });
  useEffect(() => {
    fuelData();
  }, []);
  useEffect(() => {
    if (!searchTexts) {
      getSpecListData();
    }
  }, [searchTexts]);
  useEffect(() => {
    getSpecListData();
  }, [jobStatus]);
  const initialData = {
    projectId: id,
    yardId: '',
    lastPort: '',
    lastPortDistance: 0,
    nextPort: '',
    nextPortDistance: 0,
    repairStartDate: '',
    hireRate: 0,
    fuelPrice: 0,
    fuelConsumption: 0,
    fuelCost: 0,
    repairDays: 0,
    deviationDays: 0,
    offhireDays: 0,
    deviationCost: 0,
    offhireCost: 0,
    dockingDate: '',
    etd: '',
    repairCompletionDate: '',
    arrivalAtYardDate: '',
    fuelPriceType2: 0,
    fuelConsumptionType2: 0,
    miscCost: 0,
    inDockDays: 0,
    daysAfloat: 0,
    isRepairStarted: true,
    fuelTypeOne: 0,
    fuelTypeOneName: "",
    fuelTypeTwo: 0,
    fuelTypeTwoName: "",
    isReportSaved: false
  };
  const [formData, setFormData] = useState(initialData);
  const [editModal, setEditModal] = useState(false);
  const showEditModal = () => {
    const f = {
      id: formData.id,
      projectId: formData.projectId,
      repairStartDate: formData.repairStartDate ? moment(formData.repairStartDate).format('YYYY-MM-DD') : '',
      etd: formData.etd ? moment(formData.etd).format('YYYY-MM-DD') : '',
      repairCompletionDate: formData.repairCompletionDate ? moment(formData.repairCompletionDate).format('YYYY-MM-DD') : '',
      arrivalAtYardDate: formData.arrivalAtYardDate ? moment(formData.arrivalAtYardDate).format('YYYY-MM-DD') : '',
      dockingDate: formData.dockingDate ? moment(formData.dockingDate).format('YYYY-MM-DD') : '',
      yardId: formData.yardId,
      lastPort: formData.lastPort || '',
      lastPortDistance: formData.lastPortDistance,
      nextPort: formData.nextPort || '',
      nextPortDistance: formData.nextPortDistance,
      hireRate: formData.hireRate,
      fuelPrice: formData.fuelPrice,
      fuelConsumption: formData.fuelConsumption,
      fuelCost: formData.fuelCost,
      repairDays: formData.repairDays,
      inDockDays: formData.inDockDays,
      daysAfloat: formData.daysAfloat,
      deviationDays: formData.deviationDays,
      offhireDays: formData.offhireDays,
      deviationCost: formData.deviationCost,
      offhireCost: formData.offhireCost,
      fuelPriceType2: formData.fuelPriceType2,
      fuelConsumptionType2: formData.fuelConsumptionType2,
      miscCost: formData.miscCost,
      isRepairStarted: formData.isRepairStarted,
      fuelTypeOne: formData.fuelTypeOne,
      fuelTypeOneName: formData.fuelTypeOneName,
      fuelTypeTwo: formData.fuelTypeTwo,
      fuelTypeTwoName: formData.fuelTypeTwoName,
      isReportSaved: formData.isReportSaved
    };
    if (formData) setEditModal(f);
    else {
      setEditModal(initialData);
    }
  };

  const [taskData, setTaskData] = useState([]);
  const NotCompletedTask = () => {
    setopenTaskModal(true);
    NotCompletedTasksDetails({ phaseId: '4', projectId: id }).then((res) => {
      if (res.success) {
        setTaskData(res?.data);
      }
    });
  };
  useEffect(() => {
    NotCompletedTask();
  }, []);
  const closeAddRoleModal = () => {
    setAddRole(false);
    const specListData = specList?.specQuoteList[addRole.mainKey]?.specDataList[addRole.subKey];
    const origSpecListData = originalSpecList?.specQuoteList[addRole.mainKey]?.specDataList[addRole.subKey];
    specListData.specAssignedStaff = origSpecListData.specAssignedStaff;
    specListData.specAssignedStaffName = origSpecListData.specAssignedStaffName;
    setSpecList(specList);
  };
  return (
    <div data-testid="repair">
      {taskData.length > 0 && openTaskModal ? (
        <TaskModal
          // isLoading={isLoading}
          taskData={taskData}
          openTaskModal={openTaskModal}
          setopenTaskModal={setopenTaskModal}
        />
      ) : (
        <div className="content-wrap  mb-5">
          <NavBar projectBasicDetails={projectBasicDetails} />
          <div className="row">
            <div className="col-lg-7 col-sm-12">
              <ProjectCard
                projectBasicDetails={projectBasicDetails}
                onReloadSpecData={onReloadSpecData}
                isTSI={isTSI}
                projectRepair={projectRepair}
                startRepairClicked={startRepairClicked}
                formData={formData}
                setFormData={setFormData}
                editModal={editModal}
                setEditModal={setEditModal}
                showEditModal={showEditModal}
                hasChange={hasChange}
                setHasChange={setHasChange}
                setReloadAfterStart={setReloadAfterStart}
                fuelPriceType1Value={fuelPriceType1Value}
                setFuelPriceType1Value={setFuelPriceType1Value}
                fuelPriceType2Value={fuelPriceType2Value}
                setFuelPriceType2Value={setFuelPriceType2Value}
              />
            </div>
            <div className="col-lg-5 col-sm-12">
              <JobCard statusData={statusData} />
            </div>
          </div>
          {formData?.isRepairStarted ? (
            <div className="card r-card mt-3 tender-mr">
              <div className="list-tab-head d-flex border-down">
                <div id="spec" onClick={() => setActive('spec')} className={` me-4 px-2 tab-items ${active === 'spec' && 'active'}`}>
                  Spec Items
                </div>
                <div id="nonspec" onClick={() => setActive('nonspec')} className={`me-4 px-2 tab-items ${active === 'nonspec' && 'active'}`}>
                  Non Spec Items
                </div>
              </div>
              <div className=" mb-5">
                {active === 'spec' ? (
                  <Specifications
                    specList={specList}
                    setSpecList={setSpecList}
                    mainSec={mainSec}
                    showsubSec={showsubSec}
                    onSecOpen={onSecOpen}
                    showUpdateAssignee={showUpdateAssignee}
                    showUpdateRole={showUpdateRole}
                    onSpecStatusChange={specChangeSelector}
                    onDateUpDate={onDateUpDate}
                    setSearchText={setSearchText}
                    searchText={searchText}
                    onSearch={onSearch}
                    onReloadSpecData={onReloadSpecData}
                    handleCancel={handleCancel}
                    updateCost={updateCost}
                    onProgresChange={onProgresChange}
                    projectId={id}
                    visibleSpec={visibleSpec}
                    setVisibleSpec={setVisibleSpec}
                    searchTexts={searchTexts}
                    isTSI={isTSI}
                    setSearchTexts={setSearchTexts}
                    projInfo={projInfo}
                    isCompleted={projInfo?.phase > 4}
                    projectRepair={projectRepair}
                    isLoading={isLoading}
                    projectCurrecySymbol={projectCurrecySymbol}
                    getSpecListData={getSpecListData}
                    projectBasicDetails={projectBasicDetails}
                    jobStatus={jobStatus}
                    setJobStatus={setJobStatus}
                    setOpenModal={setOpenModal}
                    setServiceData={setServiceData}
                  />
                ) : (
                  <NonSpecifications id={id} isTSI={isTSI} />
                )}
              </div>
            </div>
          ) : (
            <StartRepair
              projInfo={projectBasicDetails}
              reloadAfterStart={setReloadAfterStart}
              setStartRepairClicked={setStartRepairClicked}
              showEditModal={showEditModal}
              formData={formData}
              hasChange={hasChange}
              setHasChange={setHasChange}
            />
          )}
          {addAssignee && (
            <ModalBox
              modalClassName="medium"
              heading={!isTSI ? 'View Assignee' : addAssignee.isNew ? 'Edit Assignee' : 'Add Assignee'}
              closeModal={() => {
                setAddAssignee(false);
                if (specList?.specQuoteList[addAssignee.mainKey]?.specDataList[addAssignee.subKey]?.specAssignedUsers) {
                  specList.specQuoteList[addAssignee.mainKey].specDataList[addAssignee.subKey].specAssignedUsers = addAssignee.oldValue;
                }
              }}
              buttonName={!isTSI ? '' : 'Save'}
              saveDisable={projInfo?.phase > 4}
              onSave={() => {
                onSaveAssignee();
              }}
            >
              <AddAssignee specList={specList} addAssignee={addAssignee} setSpecList={setSpecList} isTSI={isTSI} isCompleted={projInfo?.phase > 4} />
            </ModalBox>
          )}
          {addRole && (
            <ModalBox
              modalClassName="medium"
              heading={addRole.isNew ? 'Edit Role' : 'Add Role'}
              closeModal={() => {
                closeAddRoleModal();
              }}
              buttonName="Save"
                saveDisable={projInfo?.phase > 4}
              onSave={() => {
                onSaveRole();
              }}
            >
              <AddRole
                specList={specList}
                addRole={addRole}
                assigneeList={assigneeList}
                setSpecList={setSpecList}
                isTSI={isTSI}
                isCompleted={projInfo?.phase > 4}
                supervisorUsers={supervisorUsers || []}
              />
            </ModalBox>
          )}
          {showConfirm && (
            <StatusConfirm setShowConfirm={setShowConfirm} onSpecStatusChange={onSpecStatusChange} showConfirm={showConfirm} specList={specList} />
          )}
          {openModal &&
            <ModalBox
              modalClassName="medium"
              onSave={() => saveApiCall()}
              closeModal={() => {
                setOpenModal(false);
                setnewACCode(false);
              }}
              heading='Edit Service Line'
              buttonName="Save"
            >
              <AddService
                acCodeList={acCodeList}
                serviceData={serviceData}
                setServiceData={setServiceData}
                uomList={uomList}
                isVesselUser={isVesselUser}
                newACCode={newACCode}
                setnewACCode={setnewACCode}
                projectData={projectBasicDetails}
              />
            </ModalBox>}
        </div>
      )}
    </div>
  );
};
export default Repair;
