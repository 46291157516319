/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* istanbul ignore file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useNavigate, useLocation } from 'react-router-dom';
import brandLogo from '../../images/brand-logo.svg';
import userImg from '../../images/user-placeholder.jpg';
import helper from '../../utils/helper';
import UpdatePassword from '../Admin/Users/UserList/updatePassword/updatePassword';
import { getProfileData, userRolesListing, saveProfileData } from '../Login/services';
import ViewProfile from './ViewProfile';
import { getYardNotification } from './Projects/services';
// import PrivacyPolicy from '../Policies/privacy';
// import TermsOfUse from '../Policies/TermsOfUse';
import Notification from './NotificationBell/Notification';
import PrivacyTerms from './Policy/PolicyTerms';

const Header = () => {
  const navigate = useNavigate();
  const DashboardLocation = useLocation();
  const location = window.location.pathname;
  const { logOut, multiRole, roles } = helper;
  const signOut = () => {
    logOut(location);
  };
  const initialState = {
    firstName: '',
    lastName: '',
    middleName: '',
    profileImage: ''
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [showUpdatePasswordPage, setShowUpdatePasswordPage] = useState(false);
  const [user, setuser] = useState(initialState);
  const [role, setRole] = useState([]);
  const [showView, setShowView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNotificationPanel, setShowNotificationPanel] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [notifyCount, setNotifyCount] = useState(0);
  const [isMaster, setIsMaster] = useState(false);
  const [vesselId, setVesselId] = useState('');
  const [dot, setDot] = useState(false);

  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;
  const isVesselUser = (userRole === roles.VESSEL_USER);

  useEffect(() => {
    userRolesListing().then((resp) => {
      if (resp.success) {
        setRole(resp.data);
      }
    });
  }, []);

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [DashboardLocation]);

  const updateUserDetails = () => {
    const USER_ID = localStorage.getItem('USER_ID');
    if (USER_ID) {
      getProfileData(USER_ID).then((res) => {
        if (res.data.email && res.data.roleData[0].roleType === 5) {
          setIsMaster(true);
        }
        sessionStorage.setItem('userDetails', JSON.stringify(res.data.roleData));
        res.data.roleData.length > 0 && res.data.roleData.map((i) => {
          i.vessels.length > 0 && i.vessels.map((j) => {
            setVesselId(j.id);
          });
        });
        const userDetails = res?.data;
        if (userDetails) {
          setuser(userDetails);
        }
      });
    }
  };

  useEffect(() => {
    updateUserDetails();
  }, [location]);

  const handleViewProfile = () => {
    setShowView(true);
  };

  const onViewClose = () => {
    setShowView(false);
  };

  const onUpdatePasswordPageClose = () => {
    setShowUpdatePasswordPage(false);
  };

  const handleUpdatePassword = () => {
    setShowUpdatePasswordPage(true);
  };

  const changeUser = (item) => {
    localStorage.setItem('USER_ROLE', item.role);
    localStorage.setItem('USER_ROLE_NAME', item.roleName);
    localStorage.setItem('USER_TOKEN', item.token);
    window.location.reload();
  };
  const nameUser = `${user?.firstName} ${user?.middleName} ${user?.lastName}`;

  const getAll = () => {
    setLoading(true);
    getYardNotification().then((res) => {
      if (res.success) {
        setNotificationData(res.data);
        setLoading(false);

        const ct = setNotifyCount(res.data.notifications
          .filter((items) => items.status === 1).length);
        return ct;
      }
      return '';
    });
  };

  const saveProfileDetails = (data) => {
    saveProfileData(data).then(() => {
      updateUserDetails();
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    notificationData?.notifications?.map((items) => (items?.status === 1 ? setDot(true) : ''));
  }, [notificationData]);

  let stagingBlink = '';
  let stagingBlinkHContent = 'This is the Maridock live environment. Please enter project specific actual data only.';
  if (window.location?.hostname?.includes('app.maridock')) {
    stagingBlink = 'app-build';
    stagingBlinkHContent = 'This is the Maridock live environment. Please enter project specific actual data only.';
  }
  if (window.location?.hostname?.includes('staging.maridock')) {
    stagingBlink = 'staging-build';
  }

  return (
    <div className="header-main">
      <div
        data-tooltip-id="live-content"
        data-tooltip-content={stagingBlinkHContent}
        className={`brand-logo ${stagingBlink}`}
      >
        <a href="">
          <img src={brandLogo} alt="Maridock Logo" className="brand-logo-img" />
        </a>
      </div>
      <div className={`main-nav ${isSidebarOpen ? 'show' : ''}`}>
        <ul className="d-flex nav-item">
          <li
            className={location.includes('dashboard') ? 'active' : ''}
            onClick={() => {
              if (isAdmin) {
                navigate('/admin/dashboard');
              } else {
                navigate('/user/dashboard');
              }
            }}
          >
            <a>Dashboard</a>
          </li>
          <li
            className={location.includes('projects') ? 'active' : ''}
            onClick={() => {
              if (isAdmin) {
                navigate('/admin/projectList');
              } else {
                navigate('/user/projects');
              }
            }}
          >
            <a>Projects</a>
          </li>
          <li
            className={location.includes('/user/resource') ? 'active' : ''}
            onClick={() => {
              if (isAdmin) {
                navigate('/admin/resource/web');
              } else {
                navigate('/user/resource/web');
              }
            }}
          >
            <a>Resources</a>
          </li>
        </ul>
      </div>
      <div className="usermenu-item">
        <ul className="d-flex item-set">

          <li className="me-4" title="User Manual">
            <a href={`${window.location.origin}/usermanual`} target="_blank" className="circle-ico-btn relative" rel="noreferrer">
              <span className="icon-help-book" />
            </a>
          </li>
          {!isVesselUser && (
            <>
              <li className=" relative mx-2">
                <a href="#" className="circle-ico-btn relative " onClick={() => setShowNotificationPanel(true)}>
                  <span className="icon-notification-on" onClick={() => setShowNotificationPanel(true)} />
                  <span className={dot && notifyCount !== 0 ? 'notification-bell-count' : ''}>{notifyCount === 0 ? '' : notifyCount}</span>
                </a>
              </li>
            </>
          )}
          <li id="drop-down2" className="dropdown down-animate z-index-999">
            <a href="#" className="d-flex ms-4">
              <div className="profile-img">{user.profileImage ? <img src={user.profileImage} alt="" /> : <img src={userImg} alt="" />}</div>
              <div className="d-flex flex-column u-box">
                <div className="user-nme d-flex">
                  <h6 className="head-14 d-flex text-capitalize text-break-all">
                    <span>Hello,</span>
                    <span className="truncate" data-tooltip-id="project-header" data-tooltip-content={nameUser}>
                      &nbsp;
                      {nameUser}
                    </span>
                    <Tooltip id="project-header" place="left" className="tooltip mt-3" />
                  </h6>
                </div>
                <div className="role">{localStorage.getItem('USER_ROLE_NAME')}</div>
              </div>
              <span className="icon-arrow-thin-down font-8 mt-2 pt-1 ms-2" />
            </a>
            <ul className="dropdown_menu dropdown_menu--animated down-animate">
              <div className="border-down  py-2">
                <div className="ms-3 ps-2 pb-2 font-semibold">
                  User Role
                </div>
                <div className="role-list-h">
                  {role?.map((item) => {
                    return (
                      <div
                        className={
                          multiRole[item.role] === localStorage.getItem('USER_ROLE')
                            ? 'border px-2 py-1 my-2 ms-4 me-3 bg-sky-white md-radius pointer headrole-user'
                            : 'border px-2 py-1 my-2 ms-4 me-3 md-radius pointer headrole-user'
                        }
                        onClick={() => {
                          changeUser(item);
                        }}
                      >
                        <div className="head-14">{item.roleName}</div>
                        <div className="opacity-8 font-12 truncate">
                          <span data-tooltip-id="project-heade" data-tooltip-content={item.management}>
                            {item.management}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Tooltip id="project-heade" place="top" className="tooltip mt-3" />
              </div>
              <li className="dropdown_item-1" onClick={handleViewProfile}>
                View Profile
              </li>
              <li className="dropdown_item-2" onClick={handleUpdatePassword}>
                Update Password
              </li>
              {isMaster
                && (
                  <li
                    className="dropdown_item-2"
                    onClick={() => {
                      if (vesselId) {
                        navigate(`/users/list/${vesselId}`);
                      }
                    }}
                  >
                    Manage Users
                  </li>
                )}
              <li
                className="dropdown_item-3"
                onClick={() => {
                  setShowPrivacyPolicy(true);
                }}
              >
                Privacy & Terms
              </li>
              <li
                className="dropdown_item-3"
                onClick={() => {
                  signOut();
                }}
              >
                Logout
              </li>
              {/* <li
                className="dropdown_item-3"
                onClick={() => {
                  setShowTermsPolicy(true);
                }}
              >
                Terms of Use
              </li> */}
            </ul>
          </li>
          <li className="nav-toggle">
            <a><span className="icon-menu" onClick={handleSidebarToggle} /></a>
          </li>
        </ul>
      </div>
      {showView && (
        <ViewProfile
          showView={showView}
          onClose={onViewClose}
          data={user}
          saveProfileDetails={saveProfileDetails}
        />
      )}
      {showUpdatePasswordPage && (
        <UpdatePassword
          showUpdatePasswordPage={showUpdatePasswordPage}
          setShowUpdatePasswordPage={setShowUpdatePasswordPage}
          onUpdatePasswordPageClose={onUpdatePasswordPageClose}
          data={user}
        />
      )}
      {showNotificationPanel && (
        <Notification
          loading={loading}
          setShowNotificationPanel={setShowNotificationPanel}
          showNotificationPanel={showNotificationPanel}
          notificationData={notificationData}
          setNotificationData={setNotificationData}
          getAll={getAll}
        />
      )}
      {showPrivacyPolicy && (
        <PrivacyTerms
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      <Tooltip id="live-content" place="right" className="tooltip mt-3" />
    </div>
  );
};
export default Header;
