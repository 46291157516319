/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import JoditEditor from 'jodit-react';
import { useParams } from 'react-router-dom';
import Loader from '../../../../common/loader';
import {
  downloadDailyReport, getDailyReportDataList, saveDailyReportDataList, getDailyHistory,
  getDailyReport, saveDailyReport
} from '../services';
import ModalBox from '../../../../common/ModalBox';
import showAlert from '../../../../../utils/alert';
import ReportHistory from './ReportHistory';
import helper from '../../../../../utils/helper';

const DailyReport = ({ projInfo }) => {
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isMaster = userRole === roles.VESSEL_USER;
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR;
  const [content, setContent] = useState('');
  const [tableData, setTableData] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [activeReportId, setActiveReportId] = useState('');
  const buttons = [
    // 'font',
    'undo',
    'redo',
    'fontsize',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'ul',
    'ol',
    'indent',
    'video',
    'align',
    'brush',
    'image',
    'table'
  ];
  const editorConfig = useMemo(
    () => ({
      toolbarAdaptive: false,
      tabIndex: -1,
      buttons,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: 'insert_as_html',
      uploader: {
        insertImageAsBase64URI: true,
        isMultiple: true
      },
      spellcheck: true
    }),
    []
  );
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const reloadTable = () => {
    getDailyReportDataList(id).then((res) => {
      setContent(res.data?.reportContent);
      setTableData(res.data);
      setLoading(false);
    });
    getDailyHistory(id).then((res) => {
      setList(res.data);
    });
  };

  useEffect(() => {
    setLoading(true);
    reloadTable();
  }, []);

  const getReportData = (reportId = activeReportId) => {
    setLoading(true);
    getDailyReport(reportId).then((res) => {
      setActiveReportId(reportId);
      setContent(res.data?.reportContent);
      setTableData(res.data);
      setLoading(false);
      setShow(false);
    });
  };

  const updateOldReport = () => {
    saveDailyReport(activeReportId, content).then((res) => {
      showAlert('Successfully Saved', 'success');
      setOpenDeleteModal(false);
      getReportData();
    });
  };

  const save = () => {
    const inputData = {
      ...tableData,
      reportContent: content
    };
    saveDailyReportDataList(inputData).then((res) => {
      setLoading(false);
      reloadTable();
      showAlert('Successfully saved as draft', 'success');
    });
  };

  const generateReport = () => {
    const inputData = {
      ...tableData,
      reportContent: content
    };
    saveDailyReportDataList(inputData).then((res) => {
      downloadDailyReport(id).then((res1) => {
        setOpenDeleteModal(false);
        reloadTable();
        setLoading(false);
      });
    });
  };

  return (
    <div data-testid="DailyReport" className="daily-report-template">
      <div className="relative ">
        <div className="d-flex justify-content-end black z-index-1 align-items-center" style={{ position: 'absolute', right: '0px', top: '-1px' }}>
          <div className="mt-2 ">
            <button id="report-history" disabled={!list?.length} onClick={() => setShow(true)} type="button" className="secondary-btn ">
              Report History
            </button>
          </div>
        </div>
        {loading
          ? <Loader /> : (
            <JoditEditor
              value={content}
              config={editorConfig}
              onBlur={(newContent) => setContent(newContent)}
            />
          )}
      </div>
      <div className="fixed-footer card d-flex justify-content-end">
        <div className="text-black font-12 col-6">
          <div className={userRole === 'admin' ? 'footer-sof' : 'footer-tender'}>{`© ${new Date().getFullYear()} ThinkPalm`}</div>
        </div>
        {activeReportId === '' && (isTSI || isMaster) ? (
          <button
            id="save"
            onClick={() => save()}
            type="button"
            className="secondary-btn large mx-3"
            disabled={!tableData?.isGenerateReport || projInfo?.isClosed}
          >
            Save as Draft
          </button>
        ) : (
          ''
        )}
        {isTSI ? (
          <button
            id="download"
            disabled={!tableData?.isGenerateReport || projInfo?.isClosed}
            onClick={() => setOpenDeleteModal(true)}
            type="button"
            className="primary-btn large me-3"
          >
            Save and Download Report
          </button>
        ) : (
          ''
        )}
      </div>
      {openDeleteModal ? (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          heading="Save and Download Report"
          onSave={() => {
            if (activeReportId) {
              updateOldReport();
            } else {
              generateReport();
            }
          }}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Yes"
          isNoLabel="No"
        >
          {loading ? <Loader /> : <p>Are you sure you want to save and download Report ?</p>}
        </ModalBox>
      ) : (
        ''
      )}
      {show ? <ReportHistory show={show} setShow={setShow} list={list} isDaily getReportData={getReportData} /> : ''}
    </div>
  );
};

export default DailyReport;
