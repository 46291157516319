/* istanbul ignore file */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import image from '../../../../images/vessel-thmd.jpg';
import TableSortHeader from '../../../common/TableSortHeader';
// import countryFlag from '../../../../images/flag-ind.svg';

const Cards = (props) => {
  const { vessels, handleSort, sortData } = props;
  const navigate = useNavigate();
  return (
    <div className="mb-4 scroll-wrap h-v table-responsive vessel-policy" data-testid="v-vessel-card">
      <table className="card-table">
        <thead className="v-thead-fixed">
          <tr>
            <th className="cursor-menu whitespace-pre">
              <TableSortHeader
                heading="Vessel Name & Type"
                headingProperty="name"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
            <th className="cursor-menu whitespace-pre">
              <TableSortHeader
                heading="IMO NO."
                headingProperty="imoNo"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
            <th className="text-ellipsis cursor-menu whitespace-pre">
              <TableSortHeader
                heading="Vessel Code"
                headingProperty="vesselCode"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
            <th className="cursor-menu">
              <TableSortHeader
                heading="Management"
                headingProperty="managementGroup"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
            <th className="cursor-menu">
              <TableSortHeader
                heading="Assignee"
                headingProperty="assignedTsi"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
            <th className="cursor-menu whitespace-pre">
              <TableSortHeader
                heading="Port Name"
                headingProperty="portOfRegistry"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
            <th className="cursor-menu whitespace-pre">
              <TableSortHeader
                heading="Registered Owner"
                headingProperty="registeredOwnerName"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
            <th className="cursor-menu">
              <TableSortHeader
                heading="Status"
                headingProperty="status"
                data={sortData}
                handleSort={handleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {vessels.map((item) => {
            return (
              <tr className="content-sec pointer" onClick={() => { navigate(`/admin/details/info?id=${item.id}`); }}>
                <td>
                  <div className="d-flex col-gap-1">
                    <div className="vessel-img-box">
                      <img src={item.imageUrl || image} alt="" className="thmb-md" />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6
                        className="head-14 mb-1 text-ellipsis"
                        data-tooltip-id="project-cardname"
                        data-tooltip-content={item.name}
                      >
                        {_.truncate(item.name, { length: 12 })}

                      </h6>
                      <span
                        className="text-ellipsis"
                        data-tooltip-id="project-cardCategory"
                        data-tooltip-content={item.vesselCategory}
                      >
                        {_.truncate(item.vesselCategory || '-', { length: 12 })}

                      </span>
                    </div>
                  </div>
                </td>
                <td
                  className="text-ellipsis"
                >
                  <span
                    data-tooltip-id="project-cardimoNo"
                    data-tooltip-content={item.imoNo}
                  >
                    {_.truncate(item.imoNo, { length: 12 })}
                  </span>

                </td>
                <td
                  className="text-ellipsis"
                >
                  <span
                    data-tooltip-id="project-cardvesselCode"
                    data-tooltip-content={item.vesselCode}
                  >
                    {_.truncate(item.vesselCode, { length: 12 })}
                  </span>

                </td>
                {item.managementGroup !== '()' ? (
                  <td
                    className="text-ellipsis"
                  >
                    <span
                      data-tooltip-id="project-cardmanagementGroup"
                      data-tooltip-content={item.managementGroup}
                    >
                      {_.truncate(item.managementGroup, { length: 12 })}
                    </span>

                  </td>
                ) : <td> </td>}
                <td
                  className="text-ellipsis"
                >
                  <span
                    data-tooltip-id="project-cardimoNo"
                    data-tooltip-content={item.assignedTsi}
                  >
                    {_.truncate(item.assignedTsi, { length: 12 })}
                  </span>

                </td>
                <td>
                  <div className="d-flex">
                    <span className="flag me-3">
                      {item?.flagUrl && item?.portOfRegistry ? (
                        <img src={item?.flagUrl} alt="" className="" />
                      ) : (
                        <div className="" />
                      )}
                    </span>
                    <div
                      className="text-ellipsis"
                      data-tooltip-id="project-cardRegistry"
                      data-tooltip-content={item.portOfRegistry}
                    >
                      {_.truncate(item?.portOfRegistry, { length: 12 })}
                    </div>
                  </div>
                </td>
                <td
                  className="text-ellipsis"
                >
                  <span
                    data-tooltip-id="project-cardname"
                    data-tooltip-content={item.registeredOwnerName}
                  >
                    {_.truncate(item.registeredOwnerName, { length: 12 })}
                  </span>

                </td>
                <td>
                  <div className="d-flex col-gap-2">
                    <div className="priority-ind ">
                      {item.vesselStatus === 'Active'
                        ? <span className="icon-disc active me-1" />
                        : <span className="icon-disc high me-1" />}
                      {item.vesselStatus}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {vessels.length < 1 && <tr className="bg-white d-flex justify-content-center py-2">No records found</tr>}
      <Tooltip id="project-cardname" place="top" className="tooltip mt-3" />
      <Tooltip id="project-cardCategory" place="top" className="tooltip mt-5" />
      <Tooltip id="project-cardimoNo" place="top" className="tooltip mt-3" />
      <Tooltip id="project-cardvesselCode" place="top" className="tooltip mt-3" />
      <Tooltip id="project-cardmanagementGroup" place="top" className="tooltip mt-3" />
      <Tooltip id="project-cardRegistry" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Cards;
