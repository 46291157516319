/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import SidePanel from '../../../../common/sidePanel';
import FileDownload from '../../../../common/FileDownload';

const ReportHistory = ({ list, show, setShow, isDaily, getReportData }) => {
  const getHeadingName = (i) => {
    return `${i.heading}-${moment.utc(i.reportDate).format('DD-MMM-YYYY')}`;
  };
  const getFileName = (i) => {
    return `${i.heading}-${moment.utc(i.reportDate).format('DD-MMM-YYYY')}.pdf`;
  };
  const [report, setReport] = useState(true);
  return (
    <div data-testid="PhotoReportHistory" className="row">
      <SidePanel
        title={report ? 'Report History' : 'Send Via Mail'}
        showView={show}
        size="large"
        bgColor="sky-white"
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="card">
          <div className="row border-down p-4">
            <div className="head-14 col-10">Report Name</div>
            <div className="head-14 col-2">Actions</div>
          </div>
          {list?.map((i) => {
            return (
              <div className="row border-down p-4">
                <div className="col-10 text-ellipsis">
                  <span data-tooltip-id="view-profile" data-tooltip-content={getHeadingName(i)}>
                    {getHeadingName(i)}
                  </span>
                </div>
                <div className="col-2 col-gap-1 d-flex">
                  <FileDownload
                    id={i.reportFile?.path}
                    name={getFileName(i)}
                    list={list}
                    documentId={i.documentId}
                    projectid={i.projectId}
                    isDaily={isDaily}
                    fromReportHistory
                    setReport={setReport}
                    getReportData={getReportData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </SidePanel>
      <Tooltip data-tooltip-id="view-profile" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default ReportHistory;
