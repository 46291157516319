/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { cloneDeep, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import showAlert from '../../../../../../../utils/alert';
import ModalBox from '../../../../../../common/ModalBox';
import { getComponentList } from '../../../../VesselComponents/services';
import ComponentDetailsWrap from './ComponentDetailsWrap';
import ComponentList from './ComponentList';
import {
  GetComponentListByProjectSpec,
  SaveProjrctSpecComponent,
  deleteSpecComponent,
  GetSelectedComponentstByProjectSpec
} from './services';

const index = ({ projectSpecificationId, projectId, specDetails, isTsiDisabled,
  isTSI, setIsComponentClicked, fromSpecify, fromSupplementary, activeTabSupplementary,
  projInfo, reloadAfterGTMT }) => {
  const params = qs.parse(window.location.search);
  const { vesselId } = params;
  const [loading, setLoadnig] = useState(false);
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState();
  const [components, setComponents] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [checkStatus, setCheckStatus] = useState([]);
  const [scrollId, setScrollId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const reloadTable = (page) => {
    // window.scrollTo(0, 0);
    const data = {
      page: page || currentpage,
      pageSize,
      search: searchText,
      projectId,
      specId: projectSpecificationId,
      isDescending: false,
      sortProperty: 'name'

    };
    GetComponentListByProjectSpec(data).then((res) => {
      if (res) {
        setList(res.data);
      }
    });
    GetSelectedComponentstByProjectSpec(projectId, projectSpecificationId)
      .then((res) => {
        if (res.data) {
          setCheckStatus(res.data);
        }
      });
  };

  const deleteApiCall = () => {
    setLoadnig(true);
    const payload = {
      componentId: openDeleteModal,
      specId: projectSpecificationId
    };
    deleteSpecComponent(payload).then((res) => {
      setLoadnig(false);
      if (res.success) {
        if (projectSpecificationId) {
          reloadTable();
        }
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      }
    });
    setOpenDeleteModal(false);
  };
  const saveApiCall = () => {
    setLoadnig(true);
    document.body.classList.add('model-open');
    SaveProjrctSpecComponent(checkStatus, projectId, projectSpecificationId)
      .then((res) => {
        setSearchText('');
        setLoadnig(false);
        if (res.success) {
          showAlert('Successfully Saved', 'success');
          setFormData(components);
          const url = window.location.href;
          if (url.includes('specify')) {
            reloadAfterGTMT('', projectSpecificationId, false, true);
          }
          setOpenModal(false);
          setExpanded([]);
          if (projectSpecificationId) {
            reloadTable();
          }
          setIsComponentClicked(false);
        }
      });
  };
  const handleExpand = (id) => {
    const expandedData = cloneDeep(expanded);
    if (expandedData.indexOf(id) >= 0) {
      expandedData.splice(expandedData.indexOf(id), 1);
    } else {
      expandedData.push(id);
    }
    setExpanded(expandedData);
  };
  const handleChangeType = (data) => {
    const formValue = cloneDeep(data);
    formValue.isChecked = true;
    setScrollId(`div-${formValue.id}`);
    // setLoadnig(true);
    const d = cloneDeep(checkStatus);
    const pexIds = map(d, 'id');
    if (pexIds.includes(formValue.id)) {
      const sd = d.filter((ele) => {
        if (ele.id !== formValue.id) return ele;
      });
      setCheckStatus(sd);
    } else {
      d.push(formValue);
      setCheckStatus(d);
    }
    // setTimeout(() => {
    //   setLoadnig(false);
    // }, 100);
  };
  useEffect(() => {
    setSearchText('');
    if (projectSpecificationId) {
      reloadTable();
    }
    getComponentList(vesselId, projectId).then((res) => {
      if (res) {
        setComponents(res.data);
        setFormData(res.data);
      }
    });
  }, [projectSpecificationId]);

  useEffect(() => {
    if (projectSpecificationId) {
      reloadTable();
    }
  }, [currentpage, pageSize]);

  const onCloseWindow = () => {
    setFormData(components);
    setOpenModal(false);
    setExpanded([]);
    setSearchText('');
    if (setIsComponentClicked) { setIsComponentClicked(false); }
    if (projectSpecificationId) {
      reloadTable();
    }
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
      setCurrentpage(1);
    }
  }, [searchText]);
  return (
    <div className="list-tab-content content-scroller-height" data-testid="com-3">
      <div className="d-flex mt-4 justify-content-between tab-content-ctrl">
        <div className="d-flex">
          <div className="search spec-search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => {
                  setSearchText('');
                  document.getElementById('search')?.focus();
                }}
              />
            </div>
            <span className="icon-search" onClick={() => { onSearch({ key: 'Enter' }); }} />
            <input
              type="text"
              id="search"
              className="text-box with-close large"
              placeholder="Search by component, Model"
              onKeyPress={onSearch}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          { isTSI ? (
            <button
              id="add-component"
              disabled={isTsiDisabled || (fromSupplementary && !activeTabSupplementary)
                 || projInfo?.isClosed}
              onClick={() => {
                setOpenModal(true);
                setIsComponentClicked(true);
              }}
              type="button"
              className="secondary-btn large ms-4"
            >
              Add Component
            </button>
          ) : ''}
        </div>
      </div>
      <ComponentList
        list={list}
        setOpenDeleteModal={setOpenDeleteModal}
        pageClick={pageClick}
        currentpage={currentpage}
        isTsiDisabled={isTsiDisabled}
        onSizeChange={onSizeChange}
        pageSize={pageSize}
        loading={loading}
        isTSI={isTSI}
        fromSupplementary={fromSupplementary}
        activeTabSupplementary={activeTabSupplementary}
      />
      <ComponentDetailsWrap
        openModal={openModal}
        onCloseWindow={onCloseWindow}
        loading={loading}
        formData={formData}
        fromSpecify={fromSpecify}
        isTsiDisabled={isTsiDisabled}
        expanded={expanded}
        handleExpand={handleExpand}
        handleChangeType={handleChangeType}
        checkStatus={checkStatus}
        setCheckStatus={setCheckStatus}
        scrollId={scrollId}
        saveApiCall={saveApiCall}
        specDetails={specDetails}
      />
      {openDeleteModal ? (
        <ModalBox
          modalClassName="small"
          heading="Delete"
          removeClass={!fromSpecify}
          onSave={() => deleteApiCall()}
          closeModal={() => { setOpenDeleteModal(false); }}
          buttonName="Delete"
        >
          <p>Are you sure you want to remove this component ?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};
export default index;
