import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const BulkUploadModal = (props) => {
  const { file, ondrop, setFile, generatTemplateForExcel, name,
    setIsUpload, vessels, type, existingData, isUploading } = props;
  const dataVessel = vessels ? JSON.parse(vessels.additionalData) : '';
  return (
    <div className="p-4 " data-testid="bulk-upload">
      {type === 'gtMt' ? (
        <div className="align-items-start d-flex mb-4">
          <span className="icon-Info red font-22 mt-1" />
          <span className="text-content-gray ms-4 ">
            Empty rows are not allowed in between the excel.
            Empty row will be considered as end of file
          </span>
        </div>
      ) : ''}
      {vessels
        ? (
          <div>
            <div>
              <span className="icon-Info red me-2" />
              Please note :
            </div>
            <span className="font-13 text-content-gray ms-4">
               &nbsp;1. Active license remaining
              {' '}
              {dataVessel?.planItemValueAllocated - dataVessel?.planItemValueConsumed}
              {' '}
              out of total
              {' '}
              {dataVessel?.planItemValueAllocated}
              .
            </span>
            <div className="font-13 text-content-gray ms-4">
              2. Following fields are mandatory in the excel upload :
              <div>
                &nbsp; &nbsp;&#x2022;    IMO NO
              </div>
              <div>
                 &nbsp; &nbsp;&#x2022;    Delivery Date (Only on KeyDates Sheet)
              </div>
              <div>
                 &nbsp; &nbsp;&#x2022;    Vessel Type
              </div>
            </div>
          </div>
        )
        : ''}
      {!file ? (
        <div id="fileName-div" className="drag-drop-file my-4 file-waiting">
          <input id="upload" className="p-5 align-self-center" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
          <div className="d-flex row w-full">
            <div className="d-flex flex-column justify-content-center align-items-center col-7 border-right-modal">
              <div className="icon-buble mb-2 upload-btn ">
                <span className="icon-upload-cloud" />
              </div>
              <p>Drag & Drop Files Here(.xlsx)</p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center col-5 ">
              <div className="div_btn ">
                Browse File
              </div>
            </div>
          </div>
        </div>
      )
        : (
          <div id="fileName-div" className="drag-drop-file my-4 file-added">
            <div className=" d-flex align-items-center flex-row">
              <div className="font-18 d-flex align-items-center">
                <span className="icon-file-excel me-2 pe-3 font-20"> </span>
                <div>
                  <span data-tooltip-id="view-prof" data-tooltip-content={file.name}>
                    {_.truncate(file.name, { length: 25 })}
                  </span>

                </div>
                <span id="delete" className="icon-close ms-2 ps-2 font-20" onClick={() => { setFile(''); setIsUpload(true); }}> </span>
              </div>
            </div>
            {isUploading ? <div className="d-flex align-items-center flex-row">Uploading ...</div> : ''}
          </div>
        )}
      <div className="py-3 width-fit">
        <p className="mb-2">{!existingData ? 'Please download excel template from' : 'The template downloaded will have the existing data. User can make modifications and upload via bulk upload option to reflect changes made.'}</p>
        <div id="excel" className="card p-3 d-flex align-items-center cursor-pointer" onClick={() => { generatTemplateForExcel(); }}>
          <span className="icon-file-excel brand-color pe-4" />
          <p className="value">
            {' '}
            {name}
            {' '}
            bulk upload Template
          </p>
          <span className="icon-download ps-4 brand-color" />
        </div>
      </div>
      <Tooltip id="view-prof" place="top" className="tooltip" />
    </div>
  );
};

export default BulkUploadModal;
