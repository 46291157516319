/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect } from 'react';
import Loader from '../../../../../common/loader';
import SidePanel from '../../../../../common/sidePanel';
import SpecList from '../../Specify/SpecsList';
import SpecListing from '../../Tender/SupplementarySpecs';
import AddSupplementaryJobs from '../../Tender/SupplementarySpecs/AddNewJobs/addSupplementaryJobs';
import { getNotification, getVariationQuoteList, UpdateVarStatus } from '../../Tender/SupplementarySpecs/services';
import SubmitView from '../../Tender/SupplementarySpecs/submitView';
import ReceivedQuotes from './ReceivedQuotes';
import Attachments from './ReceivedQuotes/AttachmentsReceived';
import Comments from './ReceivedQuotes/comments';
import ConfirmReceivedModal from './ReceivedQuotes/confirmReceivedModal';
import ServiceLineModal from './ServiceLineModal';

const Variation = (props) => {
  const {
    onBack,
    isTSI,
    isComponentClicked,
    title,
    onClose,
    showView,
    setIsComponentClicked,
    specDetails,
    setSpecDetails,
    saveForm,
    formData,
    setFormData,
    setIsSaved,
    isSaved,
    specId,
    setIsAddClicked,
    addClicked,
    fromVariation,
    isSubmited,
    setIsSubmitted,
    setIsSupplementaryClicked,
    initialFormData, setSpecId, specList,
    setButtonDisable, setSpecList,
    buttonDisable, sendForApproval, setSendForApproval,
    isSupplementaryClicked,
    projInfo, handleVariationPreview, loading, projectId,
    isTsiDisabled, setShowView, currency, activeTab, setActiveTab,
    showServiceLineModal, setShowServiceLineModal, setContinueServ,
    emptySpecListVariation, emptySpecList, setShowVariationPreview,
    emptySpecificationList, currencyId
  } = props;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const [varListShow, setVarListShow] = useState(true);
  const [recListShow, setRecListShow] = useState();
  const [recSpecList, setRecSpecList] = useState();
  const [showsubSec, setShowSubSec] = useState('');
  const [mainSec, setMainSec] = useState('');
  const [recConfirmModal, setRecConfirmModal] = useState(false);
  const [viewSpec, setViewSpec] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState();
  const [viewAttachments, setViewAttachments] = useState(false);
  const [allFiles, setAllFiles] = useState();
  const [comments, setComments] = useState('');
  const [viewComments, setViewComments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isSidePanel = false;
  const handleVarListShow = () => {
    setVarListShow(true);
    setActiveTab(true);
  };

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  useEffect(() => {
    setIsLoading(true);
  }, []);

  const recQuoteListShow = () => {
    getVariationQuoteList(projectId).then((res) => {
      if (res.success) {
        setIsLoading(false);
        res.data?.forEach((item) => {
          item.childData.forEach((i) => {
            if (i.variationStatus !== 0) {
              i.isSelected = true;
            } else {
              i.isSelected = false;
            }
          });
        });
        setRecSpecList(res.data);
      }
    });
  };
  const handleRecListShow = () => {
    setRecListShow(true);
    setActiveTab(false);
    recQuoteListShow();
    getNotification(projectId);
  };

  const handleConfirm = () => {
    const status = [];

    recSpecList?.forEach((item) => {
      item.childData.forEach((i) => {
        status.push({
          specId: i.id,
          status: i.variationStatus,
          comments: 'hlo'
        });
      });
    });
    UpdateVarStatus(status).then((res) => {
      if (res.success) {
        setRecConfirmModal(true);
        recQuoteListShow();
      }
    });
  };

  const handleCloseAll = () => {
    setRecConfirmModal(false);
    setShowView(false);
  };

  const viewMore = (data) => {
    setViewSpec(true);
    setShowView(false);
    setSelectedSpec(data);
  };
  const viewMoreClose = () => {
    setSelectedSpec({});
    setViewSpec(false);
    setShowView(true);
  };

  const viewMoreAttachments = (subsec) => {
    setAllFiles(subsec?.specDocuments);
    setViewAttachments(true);
  };
  const viewMoreComments = (commnt) => {
    setViewComments(true);
    setComments(commnt);
    setShowView(false);
  };

  return (
    <div data-testid="variation">
      <div className={!activeTab ? 'variationDescription' : ''}>
        {viewSpec
          ? (
            <SidePanel
              title={(
                <span
                  className=""
                  title={`${selectedSpec.specificationNo}. ${selectedSpec.title}`}
                >
                  {`${selectedSpec.specificationNo}. ${_.truncate(selectedSpec.title, { length: 30 })}`}
                </span>
              )}
              size="large"
              onClose={() => viewMoreClose()}
              showView="true"
            >
              <SpecList
                isSidePanel={isSidePanel}
                isTSI={isTSI}
                projectId={projectId}
                isSidebar
                currencyId={currencyId}
                isTsiDisabled={isTsiDisabled}
                specDetails={selectedSpec}
                selectedSpec={selectedSpec.id || ''}
                fromVariation={fromVariation}
                currency={selectedSpec?.currencySymbol}
              />
            </SidePanel>
          ) : (
            viewAttachments
              ? (
                <SidePanel
                  title="Attachments"
                  size="large"
                  onClose={() => {
                    setViewAttachments(false); setShowView(true);
                  }}
                  showView="true"
                >
                  <div className="side-panel__container bg-white specify-side">
                    <div className="side-panel__content">
                      <Attachments
                        allFiles={allFiles}
                      />
                    </div>
                  </div>
                </SidePanel>
              ) : (
                viewComments
                  ? (
                    <SidePanel
                      title="Comments"
                      size="large"
                      onClose={() => { setViewComments(false); setShowView(true); }}
                      showView="true"
                    >
                      <div className="side-panel__container bg-white specify-side">
                        <div className="">
                          <Comments
                            comments={comments}
                            fromVariation={fromVariation}
                          />
                        </div>
                      </div>
                    </SidePanel>
                  ) : (
                    <SidePanel
                      onBack={onBack}
                      size="large"
                      isComponentClicked={isComponentClicked}
                      title={title}
                      onClose={onClose}
                      buttonName="save"
                      showView={showView}
                      classHeader="header_sidepanel"
                    >
                      <div className="d-flex font-16">
                        <div
                          className={`tab-items text-center mr-15 px-2 me-2  ${activeTab ? ' active ' : ''}`}
                          type="button"
                          id="handleVarListShow"
                          onClick={handleVarListShow}
                        >
                          Variation List
                        </div>
                        <div
                          className={`tab-items text-center ml-30 px-2 ${activeTab ? '' : ' active'}`}
                          type="button"
                          onClick={() => handleRecListShow()}
                          id="recList"
                        >
                          Received Quotes
                        </div>
                      </div>
                      <div className="border-down mb-3" />
                      {showView && activeTab
                        && (
                          isSupplementaryClicked
                            ? (
                              <AddSupplementaryJobs
                                setIsComponentClicked={setIsComponentClicked}
                                specDetails={specDetails}
                                setSpecDetails={setSpecDetails}
                                saveForm={saveForm}
                                formData={formData}
                                setFormData={setFormData}
                                setIsSaved={setIsSaved}
                                isSaved={isSaved}
                                specId={specId}
                                setIsAddClicked={setIsAddClicked}
                                addClicked={addClicked}
                                fromVariation={fromVariation}
                                currency={currency}
                                activeTab={activeTab}
                                currencyId={currencyId}
                                isTSI={isTSI}
                                projInfo={projInfo}
                              />
                            )
                            : (
                              isSubmited ? (
                                <SubmitView setIsSubmitted={setIsSubmitted} />
                              ) : (
                                loading ? (
                                  <Loader />
                                ) : (
                                  <SpecListing
                                    setIsSupplementaryClicked={setIsSupplementaryClicked}
                                    setSpecDetails={setSpecDetails}
                                    setFormData={setFormData}
                                    initialFormData={initialFormData}
                                    setIsSaved={setIsSaved}
                                    setSpecId={setSpecId}
                                    specList={specList}
                                    isSubmited={isSubmited}
                                    setButtonDisable={setButtonDisable}
                                    setSpecList={setSpecList}
                                    buttonDisable={buttonDisable}
                                    sendForApproval={sendForApproval}
                                    setSendForApproval={setSendForApproval}
                                    projInfo={projInfo}
                                    setIsAddClicked={setIsAddClicked}
                                    fromVariation={fromVariation}
                                    activeTab={activeTab}
                                    emptySpecificationList={emptySpecificationList}
                                    isTSI={isTSI}
                                  />
                                )
                              )
                            )
                        )}
                      {!isSupplementaryClicked ? (
                        <footer className="side-panel__footer">
                          {varListShow && activeTab && (
                            <button
                              disabled={!(specList?.length > 0) || !isTSI || userRole === 'admin' || projInfo.phase > 4}
                              type="button"
                              className="primary-btn me-4 large"
                              id="handle-Variation"
                              onClick={() => handleVariationPreview()}
                            >
                              Send Variation
                            </button>
                          )}
                          {recListShow && !activeTab && (
                            <button
                              disabled={!(recSpecList?.length > 0) || !isTSI || projInfo.phase > 4}
                              type="button"
                              className="primary-btn me-4 large"
                              onClick={handleConfirm}
                              id="send"
                            >
                              Confirm
                            </button>
                          )}
                        </footer>
                      )
                        : (
                          <>
                            {varListShow && (
                              <footer className="side-panel__footer">
                                <button type="button" className="secondary-btn me-4 large" onClick={onBack}>
                                  Back to Variations
                                </button>
                              </footer>
                            )}

                          </>
                        )}
                      {recListShow && !activeTab && (
                        <ReceivedQuotes
                          isLoading={isLoading}
                          projectId={projectId}
                          recSpecList={recSpecList}
                          onSecOpen={onSecOpen}
                          mainSec={mainSec}
                          showsubSec={showsubSec}
                          setRecSpecList={setRecSpecList}
                          isTsiDisabled={isTsiDisabled}
                          isTSI={isTSI}
                          showView={showView}
                          setShowView={setShowView}
                          viewSpec={viewSpec}
                          setViewSpec={setViewSpec}
                          viewMore={viewMore}
                          viewMoreClose={viewMoreClose}
                          viewMoreAttachments={viewMoreAttachments}
                          viewMoreComments={viewMoreComments}
                          projInfo={projInfo}
                        />
                      )}
                      {showServiceLineModal && (
                        <ServiceLineModal
                          setShowServiceLineModal={setShowServiceLineModal}
                          setContinueServ={setContinueServ}
                          projectId={projectId}
                          emptySpecListVariation={emptySpecListVariation}
                          emptySpecList={emptySpecList}
                          setShowVariationPreview={setShowVariationPreview}
                        />
                      )}
                    </SidePanel>
                  )
              )
          )}
        {recConfirmModal && (
          <ConfirmReceivedModal isCloseModal={false} handleCloseAll={() => handleCloseAll()} />
        )}
      </div>
    </div>
  );
};
export default Variation;
