/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import qs from 'query-string';
import Card2 from './card2';
import helper from '../../../../../utils/helper';
import ShipDetailsCard from './shipDetailsCard';
import { getVesselDetails, updateGeneralInfo, saveVesselDetails } from '../../Vessel/services';
import { AccordList } from './constants';
import EditInfo from './editInfo';
import showAlert from '../../../../../utils/alert';
import { useStatus } from '../index';
import { getManagementList } from '../../Fleets/services';
import ModalBox from '../../../../common/ModalBox';
import EditAccord from './editAccord';

const Informations = () => {
  const [searchParams] = useSearchParams();
  const params = qs.parse(window.location.search);
  const [vesselInfo, setVesselInfo] = useState({});
  const [editableInfo, setEditableInfo] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [management, setManagement] = useState([]);
  const { generateOptions } = helper;
  const { status } = useStatus() || {};
  const [showModal, setShowModal] = useState(false);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [selectedManagement, setSelectedManagement] = useState('');
  const getDetails = () => {
    getVesselDetails({ id: params?.id }).then((res) => {
      if (res.data) {
        setVesselInfo(res.data);
        setEditableInfo(res.data.generalInfo);
      }
    });
  };
  useEffect(() => {
    getDetails();
    getManagementList({
    }).then((res) => {
      if (res.data) setManagement(generateOptions(res.data, 'id', 'name'));
    });
  }, [searchParams.get('id'), status]);
  const onClose = () => {
    setShowEdit(false);
    editableInfo.managementGroup = vesselInfo.generalInfo.managementGroup;
    editableInfo.managementGroupId = vesselInfo.generalInfo.managementGroupId;
  };
  const onSave = () => {
    const i = cloneDeep(editableInfo);
    i.vesselId = editableInfo?.vesselId || params?.id;
    updateGeneralInfo(i).then((res) => {
      if (res.success) {
        showAlert('Successfully Updated', 'success');
        getDetails();
        setShowEdit(false);
      }
    });
  };
  const onEdit = () => {
    setShowEdit(true);
  };
  const onSelection = () => {
    const generalInfo = { ...editableInfo };
    generalInfo.managementGroup = selectedManagement?.label;
    generalInfo.managementGroupId = selectedManagement?.value;
    setEditableInfo(generalInfo);
    setShowModal(false);
  };
  const onCloseEdit = () => {
    setUpdateInfo(false);
  };
  const onUpdateSave = () => {
    saveVesselDetails(vesselInfo).then((res) => {
      if (res.success) {
        getDetails();
        setUpdateInfo(false);
      }
    });
  };
  return (
    <div className="pt-4" data-testid="information">
      <EditInfo
        showEdit={showEdit}
        editableInfo={editableInfo}
        setEditableInfo={setEditableInfo}
        onClose={onClose}
        onSave={onSave}
        setShowModal={setShowModal}
        setSelectedManagement={setSelectedManagement}
        management={management}
      />
      <ShipDetailsCard vesselInfo={vesselInfo} onEdit={onEdit} />
      {/* <Card vesselInfo={vesselInfo.classification} /> */}
      {
        AccordList.map((list) => {
          return <Card2 data={list} vesselInfo={vesselInfo} setUpdateInfo={setUpdateInfo} />;
        })
      }
      {showModal && (
      <ModalBox
        modalClassName="medium"
        heading="Selecting Another Management"
        closeModal={() => setShowModal(false)}
        onSave={() => onSelection()}
        buttonName="Yes"
      >
        <p>
          Are you sure you want to change the management?
        </p>
      </ModalBox>
      )}
      <EditAccord
        showModal={updateInfo}
        vesselInfo={vesselInfo}
        updateInfo={updateInfo}
        onClose={onCloseEdit}
        setVesselInfo={setVesselInfo}
        onSave={onUpdateSave}
      />
    </div>
  );
};
export default Informations;
