/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../../../../common/InputType1';
import pdf from '../../../../../../../images/pdf.png';
import doc from '../../../../../../../images/doc.png';
import zip from '../../../../../../../images/zip.png';
import tiff from '../../../../../../../images/tiff.png';

const AddFile = (props) => {
  const { ondrop, formData, setFormData } = props;

  const handleChange = (e) => {
    const data = { ...formData };
    data.fname = e.target.value;
    if (Array.isArray(data.specDocument)) {
      data.specDocument.forEach((item, ind) => {
        item.fname = `${data.fname}-${ind + 1}`;
      });
    }
    setFormData(data);
  };

  const deleteAttach = (index) => {
    const attach = { ...formData };
    attach.specDocument.splice(index, 1);
    if (Array.isArray(attach.specDocument)) {
      attach.specDocument.forEach((item, ind) => {
        if (attach.specDocument.length > 1) {
          item.fname = `${attach.fname}-${ind + 1}`;
        } else {
          item.fname = attach.fname;
        }
      });
    }
    setFormData(attach);
  };
  return (
    <div>
      <div className="col-12" data-testid="add-file">
        <div className="label-space">
          <InputType1 autoFocus className="modal-input" label="Attachment Name" name="fname" id="fname" value={formData?.fname} onChange={(e) => handleChange(e, 'fname')} isMandatory="true" />
        </div>
      </div>
      <div className={`drag-drop-file-attach my-4 p-3 ${formData.specDocument?.path && 'file-added '}`}>
        <div className="icon-buble">
          <span className="icon-file-text2" />
        </div>
        <input className="align-self-center" id="path" title="" type="file" multiple onChange={(e) => { ondrop(e); }} />
        <div className="mt-4">
          <p>Choose a file or drag it here(jpeg, jpg, png, tiff, pdf, doc, docx, zip(max size : 5 MB))</p>
        </div>
      </div>
      {formData.specDocument.length > 0
        && (
          <div>
            <span>Selected Images</span>
            <div className="selected-parent-gallery spec-attach-scroll">
              {formData.specDocument.map((item, index) => {
                const icon = item?.name.endsWith('.pdf') ? pdf : item?.name.endsWith('.doc') ? doc
                  : item?.name.endsWith('.zip') ? zip : item?.name.endsWith('.tiff') || item?.name.endsWith('.tif')
                    ? tiff : '';
                return (
                  <div key={item.path} className="child-gallery mt-4 attachIcons">
                    {icon ? (
                      <img src={icon} alt="img" className="selected-image-dimension" />
                    ) : (
                      <img src={item?.path} alt="img" className="selected-image-dimension" />
                    )}
                    <button
                      type="button"
                      id="delete"
                      className="link-btn clear-file"
                      onClick={() => deleteAttach(index)}
                    >
                      <span className="icon-delete ms-4 font-20" />
                    </button>
                    <div>{item.fname}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      <Tooltip id="project-add" place="top" className="tooltip m-4" />
    </div>
  );
};

export default AddFile;
