/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import _, { cloneDeep, toNumber } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../utils/helper';
import InputType1 from '../../../../../common/InputType1';
import { GetFuelData } from '../../../../../Admin/DataConfig/GeneralConfig/services';

const EditModal = ({ formData, setFormData, setHasChange, projectBasicDetails }) => {
  const { charValidation, handleValidation, restrictDecimal, fieldValidation } = helper;
  const handleFormChange = (field, value, field1) => {
    setHasChange(true);
    const fd = cloneDeep(formData);
    if (
      field === 'lastPort'
      || field === 'nextPort'
      || field === 'dockingDate'
      || field === 'repairCompletionDate'
      || field === 'arrivalAtYardDate'
      || field === 'etd'
      || field === 'repairStartDate'
    ) {
      fd[field] = charValidation(value);
      if (field === 'dockingDate' || field === 'repairCompletionDate') {
        if (fd.dockingDate && fd.repairCompletionDate) {
          const dockingDate = moment(new Date(fd.dockingDate));
          const repairCompletionDate = moment(new Date(fd.repairCompletionDate));
          fd.inDockDays = repairCompletionDate.diff(dockingDate, 'days');
        }
      } else if (field === 'etd' || field === 'arrivalAtYardDate') {
        if (fd.arrivalAtYardDate && fd.etd) {
          const arrivalAtYardDate = moment(new Date(fd.arrivalAtYardDate));
          const etd = moment(new Date(fd.etd));
          fd.repairDays = etd.diff(arrivalAtYardDate, 'days') + 1;
        }
      }
    } else if (field === 'fuelTypeOneName' || field === 'fuelTypeTwoName') {
      fd[field1] = value.value;
      fd[field] = value.label;
    } else {
      // let output = value ? value.replace(/^0+/, '') : 0;
      // if (value.length > 10) {
      // eslint-disable-next-line no-unused-vars
      // output = value.substr(0, validLength);
      // }
      fd[field] = toNumber(fieldValidation(value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6));
    }
    fd.daysAfloat = toNumber(fd.repairDays) - toNumber(fd.inDockDays);
    fd.offhireDays = _.round(toNumber(fd.deviationDays) + toNumber(fd.repairDays), 2);
    fd.offhireCost = toNumber((fd.offhireDays * parseFloat(fd.hireRate)).toFixed(2));
    // eslint-disable-next-line max-len
    fd.fuelCost = toNumber(
      (parseFloat(fd.fuelPrice) * parseFloat(fd.fuelConsumption)
        + parseFloat(fd.fuelPriceType2) * parseFloat(fd.fuelConsumptionType2)).toFixed(2)
    );
    // eslint-disable-next-line max-len
    fd.deviationCost = toNumber((parseFloat(fd.fuelCost) + parseFloat(fd.offhireCost) + parseFloat(fd.miscCost)).toFixed(2));

    setFormData(fd);
    handleValidation(
      [
        'lastPort',
        'lastPortDistance',
        'nextPort',
        'nextPortDistance',
        // 'repairStartDate',
        'hireRate',
        'fuelPrice',
        'fuelConsumption',
        'fuelCost',
        'repairDays',
        'deviationDays',
        'offhireDays',
        'deviationCost',
        'offhireCost',
        'dockingDate',
        'etd',
        'repairCompletionDate',
        'arrivalAtYardDate',
        'fuelPriceType2',
        'fuelConsumptionType2',
        'miscCost',
        'daysAfloat',
        'inDockDays',
        'repairStartDate',
        'fuelTypeOne',
        'fuelTypeOneName',
        'fuelTypeTwo',
        'fuelTypeTwoName'
      ],
      fd,
      field
    );
  };
  const disabled = true;
  const viewOnly = projectBasicDetails?.phase > 4;
  const [tableData, setTableData] = useState([]);
  const reloadTable = () => {
    GetFuelData({}).then((res) => {
      setTableData(res.data);
    });
  };
  const options = tableData?.map((opt) => {
    return { label: opt.name, value: opt.id };
  });
  const optionsForFuelTypeOne = options.filter((option) => option.value !== formData.fuelTypeTwo);
  const optionsForFuelTypeTwo = options.filter((option) => option.value !== formData.fuelTypeOne);

  useEffect(() => {
    reloadTable();
  }, []);
  const data = tableData.map((i) => i.name);
  return (
    <div data-testid="edit-modal">
      <div className="d-flex justify-content-between ">
        <div>
          <span className="text-content-grey">Repair Start Date</span>
          {!formData?.isRepairStarted ? <span className="text-primaryRed-1">*</span> : ''}
        </div>
      </div>
      <div className="d-flex repair-date-container">
        <InputType1
          value={moment(formData.repairStartDate).format('YYYY-MM-DD')}
          id="repairStartDate"
          onChange={(e) => handleFormChange('repairStartDate', e.target.value)}
          disabled={formData?.isReportSaved}
          className="modal-input my-2 col-11"
          type="date"
          readOnly={formData?.isReportSaved}
        />
        {formData?.isReportSaved && (
          <span
            className="icon-Info red repair-date-tooltip"
            data-tooltip-id="project-repair-date"
            data-tooltip-content="Repair start date cannot be modified after the first Daily Report is generated"
          />
        )}
      </div>
      <div className="d-flex justify-content-between ">
        <div>
          <span className="text-content-grey">Last Porting Location</span>
          <span className="text-primaryRed-1">*</span>
        </div>
      </div>
      <InputType1
        autoFocus
        value={formData.lastPort}
        id="lastPort"
        disabled={viewOnly}
        onChange={(e) => handleFormChange('lastPort', e.target.value)}
        className="modal-input my-2 col-11"
        type="text"
      />
      <div className="pt-3">
        <div className="d-flex justify-content-between ">
          <div>
            <span className="text-content-grey">Next Porting Location</span>
            <span className="text-primaryRed-1">*</span>
          </div>
        </div>
        <InputType1
          value={formData.nextPort}
          id="nextPort"
          disabled={viewOnly}
          onChange={(e) => handleFormChange('nextPort', e.target.value)}
          className="modal-input my-2 col-11"
          type="text"
        />
      </div>
      <div className="d-flex flex-wrap">
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Docking Date</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={moment(formData.dockingDate).format('YYYY-MM-DD')}
            id="dockingDate"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('dockingDate', e.target.value)}
            className="modal-input my-2"
            type="date"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Date of arrival at yard</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={moment(formData.arrivalAtYardDate).format('YYYY-MM-DD')}
            id="arrivalAtYardDate"
            disabled={formData?.isRepairStarted}
            onChange={(e) => handleFormChange('arrivalAtYardDate', e.target.value)}
            className="modal-input my-2 col-11"
            type="date"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Undocking Date</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={moment(formData.repairCompletionDate).format('YYYY-MM-DD')}
            id="repairCompletionDate"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('repairCompletionDate', e.target.value)}
            className="modal-input my-2"
            type="date"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">ETD</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={moment(formData.etd).format('YYYY-MM-DD')}
            id="etd"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('etd', e.target.value)}
            className="modal-input my-2 col-11"
            type="date"
          />
        </div>
        <div
          className="w-50P me-4"
          data-tooltip-content="Days In Dock = Difference between undocking date and docking date"
          data-tooltip-id="project-dashboard"
        >
          <div className="">
            <span className="text-content-grey text-capitalize">Days In Dock</span>
          </div>
          <InputType1
            disabled={disabled}
            value={formData.inDockDays}
            id="inDockDays"
            // disabled={viewOnly}
            onChange={(e) => handleFormChange('inDockDays', e.target.value)}
            className="modal-input my-2"
          />
        </div>
        <div
          className="w-50P me-4"
          data-tooltip-content="Days Afloat = Difference between Total Repair Days and Days In Dock"
          data-tooltip-id="project-dashboard"
        >
          <div className="">
            <span className="text-content-grey text-capitalize">Days Afloat</span>
          </div>
          <InputType1
            disabled={disabled}
            value={formData.daysAfloat}
            id="daysAfloat"
            // disabled={viewOnly}
            onChange={(e) => handleFormChange('daysAfloat', e.target.value)}
            className="modal-input my-2 col-11"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Total Deviation Days</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            disabled={viewOnly}
            value={formData.deviationDays ? formData.deviationDays : ''}
            id="deviationDays"
            onChange={(e) => handleFormChange('deviationDays', e.target.value)}
            className="modal-input my-2"
            type="number"
            min="0"
            step="0.01"
          />
        </div>
        <div
          className="w-50P me-4"
          data-tooltip-content="Total Repair Days = Difference between ETD and Date Of Arrival At Yard"
          data-tooltip-id="project-dashboard"
        >
          <div className="">
            <span className="text-content-grey text-capitalize">Total Repair Days</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            disabled={disabled}
            value={formData.repairDays}
            id="repairDays"
            // disabled={viewOnly}
            onChange={(e) => handleFormChange('repairDays', e.target.value)}
            className="modal-input my-2 col-11"
          />
        </div>
        <div
          className="w-50P me-4"
          data-tooltip-content="Total Off Hire Days = Total Deviation Days + Total Repair Days"
          data-tooltip-id="project-dashboard"
        >
          <div className="">
            <span className="text-content-grey text-capitalize">Total Off Hire Days</span>
          </div>
          <InputType1
            disabled={disabled}
            value={formData.offhireDays}
            id="offhireDays"
            // disabled={viewOnly}
            onChange={(e) => handleFormChange('offhireDays', e.target.value)}
            className="modal-input my-2"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Hire Rate (Per Day)</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={formData.hireRate}
            id="hireRate"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('hireRate', e.target.value)}
            className="modal-input my-2 col-11"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">fuel Type1</span>
            <span className="text-primaryRed-1">*</span>
            <div className="mb-3 mt-3">
              <Select
                options={optionsForFuelTypeOne}
                className="modal-select"
                value={optionsForFuelTypeOne.find((option) => option.value
                  === formData.fuelTypeOne)}
                onChange={(e) => {
                  handleFormChange('fuelTypeOneName', e, 'fuelTypeOne');
                }}
                placeholder="Select Category"
                noMargin
              />
            </div>
          </div>
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Fuel Type1 - Price</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          {/* <Select className="modal-select">
            {tableData.map((item) => (
              <option value={item.id}>{item.name}</option>
            ))}
          </Select> */}
          <InputType1
            value={formData.fuelPrice || ''}
            id="fuelPrice"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('fuelPrice', e.target.value)}
            className="modal-input my-2 col-11"
            type="number"
            min="0"
            step="0.01"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Fuel Type1 - Consumption</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={formData.fuelConsumption}
            id="fuelConsumption"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('fuelConsumption', e.target.value)}
            className="modal-input my-2"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">fuel Type2</span>
            <span className="text-primaryRed-1">*</span>
            <div className="mb-3 mt-3">
              <Select
                options={optionsForFuelTypeTwo}
                className="modal-select col-11"
                value={optionsForFuelTypeTwo.find((option) => option.value
                  === formData.fuelTypeTwo)}
                onChange={(e) => {
                  handleFormChange('fuelTypeTwoName', e, 'fuelTypeTwo');
                }}
                placeholder="Select Category"
                noMargin
              />
            </div>
          </div>
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Fuel Type2 - Price</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={formData.fuelPriceType2 || ''}
            id="fuelPriceType2"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('fuelPriceType2', e.target.value)}
            className="modal-input my-2"
            type="number"
            min="0"
            step="0.01"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Fuel Type2 - Consumption</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={formData.fuelConsumptionType2}
            id="fuelConsumptionType2"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('fuelConsumptionType2', e.target.value)}
            className="modal-input my-2 col-11"
          />
        </div>
        <div className="w-50P me-4">
          <div className="">
            <span className="text-content-grey text-capitalize">Misc Cost</span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={formData.miscCost}
            id="miscCost"
            disabled={viewOnly}
            onChange={(e) => handleFormChange('miscCost', e.target.value)}
            className="modal-input my-2"
          />
        </div>
        <div className="w-50P me-4" data-tooltip-content="Off Hire cost = Total Off Hire Days * Hire Rate" data-tooltip-id="project-dashboard">
          <div className="">
            <span className="text-content-grey text-capitalize">Off Hire cost</span>
          </div>
          <InputType1
            disabled={disabled}
            value={formData.offhireCost}
            id="offhireCost"
            // disabled={viewOnly}
            onChange={(e) => handleFormChange('offhireCost', e.target.value)}
            className="modal-input my-2 col-11"
          />
        </div>
        <div
          className="w-50P me-4"
          data-tooltip-content="Fuel cost = (Fuel Price Type1 * Fuel Type1 Consumption) + (Fuel Price Type2 * Fuel Type2 Consumption)"
          data-tooltip-id="project-dashboard"
        >
          <div className="">
            <span className="text-content-grey text-capitalize">Fuel cost</span>
          </div>
          <InputType1
            disabled={disabled}
            value={formData.fuelCost}
            id="fuelCost"
            // disabled={viewOnly}
            onChange={(e) => handleFormChange('fuelCost', e.target.value)}
            className="modal-input my-2"
          />
        </div>
        <div className="w-50P me-4" data-tooltip-content="Deviation cost = Off hire Cost + Fuel Cost + Misc Cost" data-tooltip-id="project-dashboard">
          <div className="">
            <span className="text-content-grey text-capitalize">Deviation cost</span>
          </div>
          <InputType1
            disabled={disabled}
            value={formData.deviationCost}
            id="deviationCost"
            // disabled={viewOnly}
            onChange={(e) => handleFormChange('deviationCost', e.target.value)}
            className="modal-input my-2 col-11"
          />
        </div>

        <Tooltip id="project-dashboard" place="top" className="tooltip mt-4" />
        <Tooltip id="project-repair-date" place="left" className="tooltip mb-4 repair-tooltip-cont" />
      </div>
    </div>
  );
};

export default EditModal;
