import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import InputType1 from '../../../../common/InputType1';
import SidePanel from '../../../../common/sidePanelCustom';

const EditInfo = (props) => {
  const { showEdit, onClose, onSave, editableInfo, setEditableInfo,
    management, setShowModal, setSelectedManagement } = props;

  // eslint-disable-next-line no-unused-vars
  const handleChange = (e, label, val) => {
    const generalInfo = { ...editableInfo };
    if (label) {
      if (editableInfo?.managementGroupId) {
        setShowModal(true);
        setSelectedManagement(e);
      } else {
        generalInfo.managementGroup = e?.label;
        generalInfo.managementGroupId = e?.value;
      }
    } else {
      generalInfo[e.target.name] = e.target.value?.substring(0, 199);
    }
    setEditableInfo(generalInfo);
  };
  const handleDateChange = (e) => {
    const generalInfo = { ...editableInfo };
    if (e.target.value) { generalInfo[e.target.name] = `${e.target.value}T00:00:00Z`; }
    setEditableInfo(generalInfo);
  };
  return (
    <SidePanel title="Update General Info" size="large" onClose={onClose} buttonName="save" showView={showEdit} onSave={onSave}>
      <div className="side-panel__container bg-white">
        <div className="side-panel__content">
          <div className="row mb-3" data-testid="edit-info">
            <div className="col-6">
              <label>Management</label>
              <Select
                autoFocus
                className="modal-select select-height"
                id="management"
                options={management}
                value={editableInfo?.managementGroupId
                  ? management.filter((mgmntId) => mgmntId.value === editableInfo?.managementGroupId)[0] : ''}
                onChange={(e) => { handleChange(e, 'managementGroup', 'managementGroupId'); }}
              />
            </div>
            <div className="col-6">
              <InputType1
                className="modal-input"
                id="registry"
                label="Port Name"
                name="portOfRegistry"
                value={editableInfo?.portOfRegistry}
                onChange={handleChange}
              />
            </div>
            {/* <div className="col-6 mt-4">
              <InputType1 className="modal-input" label="Group Owner"
              id="owner" name="groupOwner" value={editableInfo?.groupOwner}
              onChange={handleChange} />
            </div> */}
            {/* <div className="col-6 mt-4">
              <InputType1 className="modal-input" label="Vessel Previous Name" id="prename"
              name="previousName" value={editableInfo?.previousName} onChange={handleChange} />
            </div> */}
            <div className="col-6 mt-4">
              <div className="date-picker">
                <label>Takeover Date</label>
                <input id="takeover" max={moment().subtract(1, 'days').format('YYYY-MM-DD')} onKeyDown={(e) => e.preventDefault()} type="date" className="text-box large full-width" name="takeoverDate" value={moment(editableInfo?.takeoverDate).format('YYYY-MM-DD')} onChange={handleDateChange} />
              </div>
            </div>
            <div className="col-6 mt-4">
              <div className="date-picker">
                <label>Handedover Date</label>
                <input id="handover" max={moment().subtract(1, 'days').format('YYYY-MM-DD')} onKeyDown={(e) => e.preventDefault()} type="date" className="text-box large full-width" name="handoverDate" value={moment(editableInfo?.handoverDate).format('YYYY-MM-DD')} onChange={handleDateChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="side-panel__footer">
        <button type="button" className="secondary-btn me-4" onClick={onClose}>Cancel</button>
        <button type="button" className="primary-btn me-4" onClick={onSave}>Save</button>
      </footer>
    </SidePanel>
  );
};
export default EditInfo;
