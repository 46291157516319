import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import _ from 'lodash';

const VesselInfo = (props) => {
  const { vesselInfo } = props;
  const a = [{ label: 'IMO NO', key: 'imoNo' },
    { label: 'Vessel Type', key: 'vesselType' },
    { label: 'Vessel Code', key: 'vesselCode' },
    { label: 'Vessel Subtype', key: 'vesselSubType' },
    { label: 'Keel laid Date', key: 'keelLaidDate', isDate: true },
    { label: 'Launched Date', key: 'launchedDate', isDate: true },
    { label: 'Management', key: 'managementGroup' },
    { label: 'Delivery Date', key: 'deliveryDate', isDate: true },
    { label: 'Port Name', key: 'portOfRegistry' },
    { label: 'Takeover Date', key: 'takeoverDate', isDate: true },
    { label: 'Flag', key: 'flagName', type: 'flag' },
    // { label: 'Group Owner', key: 'groupOwner' },
    { label: 'Handedover Date', key: 'handoverDate', isDate: true },
    { label: 'Hull No', key: 'hullNo' },
    // { label: 'Date of Name Change', key: 'dateOfNameChange', isDate: true },
    // { label: 'Anniversary Date', key: 'anniversaryDate', isDate: true },
    // { label: 'Official No', key: 'officialNo' },
    // { label: 'Last Docking Date', key: 'previousDdDate', isDate: true },
    { label: 'Built Date', key: 'builtDate', isDate: true },
    { label: 'Yard Built', key: 'yardBuiltIn' },
    { label: 'Vessel Class', key: 'vesselClass' },
    { label: 'Registered Owner', key: 'registeredOwner' }
    // { label: 'Status Change Date', key: 'statusChangedDate', isDate: true }
  ];
  return (
    <div className=" mb-4 col-12">
      <div className="head-16">General Information</div>
      <div className="col-12 row mb-3 font-14 mt-4">
        {
        a.map((item) => {
          return (
            <div className="d-flex col-lg-6 col-sm-12 mt-2 align-items-end">
              <label className="col-6">
                {item.label}
                <span className="ms-2">:</span>
              </label>
              { item?.isDate
                ? (
                  <div
                    className="value col-6 whitespace-pre"

                  >
                    <span
                      data-tooltip-id="project-vesselInfo"
                      data-tooltip-content={vesselInfo?.generalInfo && vesselInfo?.generalInfo[item?.key] ? moment(vesselInfo?.generalInfo[item?.key]).format('DD-MMM-YYYY') : '-'}
                    >
                      {vesselInfo?.generalInfo && vesselInfo?.generalInfo[item?.key] ? moment(vesselInfo?.generalInfo[item?.key]).format('DD-MMM-YYYY') : '-'}

                    </span>

                  </div>
                ) : item.type === 'flag' ? (
                  <div className="d-flex col-6 value text-ellipsis pe-1">
                    <span
                      className="d-flex"
                      data-tooltip-id="project-vesselInfo"
                      data-tooltip-content={`${vesselInfo?.generalInfo && (vesselInfo?.generalInfo[item.key] || '-')}`}
                    >
                      {vesselInfo?.generalInfo?.flagUrl && (
                        <div className="port-height">
                          <img src={vesselInfo?.generalInfo?.flagUrl} alt="" className="me-1" />
                        </div>
                      )}

                      <div className={vesselInfo?.generalInfo?.flagUrl ? 'ms-1' : ''}>
                        {' '}
                        {vesselInfo?.generalInfo && vesselInfo?.generalInfo[item.key] ? _.truncate(vesselInfo?.generalInfo[item.key], { length: 15 }) : '-'}
                      </div>

                    </span>
                  </div>
                ) : (
                  <div
                    className="value col-6 whitespace-pre"

                  >
                    <span
                      data-tooltip-id="project-vesselInfo"
                      data-tooltip-content={vesselInfo?.generalInfo && vesselInfo?.generalInfo[item?.key] ? vesselInfo?.generalInfo[item?.key] : '-'}
                    >
                      {_.truncate(vesselInfo?.generalInfo && vesselInfo?.generalInfo[item?.key] ? vesselInfo?.generalInfo[item?.key] : '-', {
                        length: 15,
                        separator: ' '
                      })}
                    </span>

                  </div>
                )}
              <Tooltip id="project-vesselInfo" place="top" className="tooltip mt-3" />
            </div>
          );
        })
      }
      </div>
    </div>
  );
};

export default VesselInfo;
