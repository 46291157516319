import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import SidePanel from '../common/sidePanel';
import userImg from '../../images/user-placeholder.jpg';
import EditFieldWrapper from './Users/UserList/editFieldWrapper';

const ViewProfile = (props) => {
  const { showView, onClose, data, saveProfileDetails } = props;
  const [userData, setUserData] = useState({});
  const [edit, setEdit] = useState({
    phone: false,
    mobile: false,
    name: false,
    profileName: null
  });
  const [formData, setFormData] = useState({
    phone: null,
    mobile: null,
    name: null,
    firstName: null,
    middleName: null,
    lastName: null
  });
  const getVessels = (item) => {
    let list = [];
    if (item && item?.vessels) {
      list = item.vessels.map((v) => {
        return v.name;
      });
    }
    return list.join().replace(/,/g, ', ');
  };

  useEffect(() => {
    setUserData({ ...data });
    setFormData({ ...data });
  }, [data]);

  const handleChange = (field = null, actionType = 'edit') => {
    if (actionType === 'edit') {
      setFormData({ ...userData });
      setEdit({
        [field]: true
      });
    } else if (actionType === 'save') {
      let reqData = {};
      if (field === 'profileName') {
        reqData = {
          ...userData,
          firstName: formData.firstName,
          middleName: formData.middleName,
          lastName: formData.lastName
        };
        setUserData(reqData);
      } else {
        reqData = {
          ...userData,
          [field]: formData[field]
        };
        setUserData(reqData);
      }
      saveProfileDetails(reqData);
      setEdit({});
    } else if (actionType === 'cancel') {
      setEdit({});
      setFormData({ ...userData });
    }
  };
  const handleFormData = (formUserData, field = null, event = {}) => {
    const formObj = formUserData;
    if (field === 'phone' || field === 'mobile') {
      formObj[event.target.name] = event.target.value.replace(/[^0-9 )(+-]/g, '').substring(0, 15);
    }
    setFormData(formObj);
  };

  return (
    <div data-testid="viewProfile">
      <SidePanel title="User Details" showView={showView} onClose={onClose}>
        {showView && (
          <div className="row mt-3">
            <div className="col-3 d-flex justify-content-center">
              {data?.profileImage ? <img src={data?.profileImage} className="thmb-xlg" alt="" /> : <img src={userImg} className="thmb-xlg" alt="" />}
            </div>
            <div className="col-9 font-16">
              <div className="d-flex justify-content-between">
                {!edit.profileName && (
                  <div className="d-flex head-16 text-capitalize mb-1 text-break-all">
                    <div>
                      <span className="text-ellipsis pe-2" data-tooltip-id="view-prof" data-tooltip-content={`${data.firstName}`}>
                        {_.truncate(formData?.firstName, {
                          length: 15,
                          separator: ' '
                        })}
                      </span>

                    </div>
                    <div>
                      <span className="text-ellipsis pe-2" data-tooltip-id="view-prof" data-tooltip-content={`${data.middleName}`}>
                        {_.truncate(formData?.middleName, {
                          length: 15,
                          separator: ' '
                        })}
                      </span>
                    </div>
                    <div>
                      <span className="text-ellipsis pe-2" data-tooltip-id="view-prof" data-tooltip-content={`${data.lastName}`}>
                        {_.truncate(formData?.lastName, {
                          length: 15,
                          separator: ' '
                        })}
                      </span>
                    </div>
                    <span className="icon-edit font-16 me-3 pointer" data-tooltip-id="view-prof" data-tooltip-content="Edit" onClick={() => handleChange('profileName', 'edit')} />
                  </div>
                )}
                <EditFieldWrapper
                  formData={formData}
                  userData={userData}
                  edit={edit}
                  field="profileName"
                  handleFormData={handleFormData}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-3 d-flex flex-column user-details-edit">
                {data?.isLocalUser ? '' : (
                  <>
                    <div className="mb-3 d-flex">
                      <div className="d-flex gap-3 col-1">
                        <span className="icon-email font-22 me-3 col-4" />
                        {data?.email}
                      </div>
                    </div>
                    <span className="mb-3 d-flex user-phone-wrap">
                      <EditFieldWrapper
                        formData={formData}
                        userData={userData}
                        icons="icon-cell-phone"
                        edit={edit}
                        field="mobile"
                        handleFormData={handleFormData}
                        handleChange={handleChange}
                      />
                    </span>
                    <span className="mb-3  d-flex user-phone-wrap">
                      <EditFieldWrapper
                        formData={formData}
                        userData={userData}
                        icons="icon-phone"
                        edit={edit}
                        field="phone"
                        handleFormData={handleFormData}
                        handleChange={handleChange}
                      />
                    </span>
                  </>
                )}
                <span className="mb-3 d-flex">
                  <span className="me-3 head-14 mt-1">Username : </span>
                  <span data-tooltip-id="view-profile" data-tooltip-content={`${data.userName}`}>
                    {_.truncate(data?.userName, {
                      length: 30,
                      separator: ' '
                    })}
                  </span>

                </span>
              </div>
              <h4 className="mt-5 mb-3  head-16">Management &amp; Role</h4>
              <div className="mt-4">
                {data?.roleData?.map((item) => {
                  return (
                    <div key={item} className="skywhite-bg p-4 my-3 md-radius relative">
                      <div className="text-capitalize head-16 pb-2">{item.management}</div>
                      <div className="d-flex">
                        <label className="pe-2 head-14">
                          Role
                          {' '}
                          :
                          {' '}
                        </label>
                        <div className="text-capitalize pb-1">
                          {item.role}
                        </div>
                        {data?.isVesselUser && !data?.isLocalUser ? <div className="">(Vessel Reviewer)</div> : ''}
                      </div>
                      <div className="">
                        <label className="pe-2 head-14">
                          {data?.isLocalUser ? 'Vessel' : 'Vessels'}
                          {' '}
                          :
                          {' '}
                        </label>
                        <span className="text-capitalize chip me-2">{getVessels(item) || '-'}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Tooltip id="view-prof" place="top" className="tooltip " />
            <Tooltip id="view-profile" place="top" className="tooltip mt-3" />
          </div>
        )}
      </SidePanel>
    </div>
  );
};
export default ViewProfile;
