import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import Select from 'react-select';
import helper from '../../../../../../../../utils/helper';
import InputType1 from '../../../../../../../common/InputType1';

const AddService = ({ serviceData, setServiceData, uomList, acCodeList, fromSupplementary,
  isVesselUser, newACCode, setnewACCode, projectData }) => {
  const { ZERO_UUID } = helper;
  const handleChange = (e, field) => {
    const fd = cloneDeep(serviceData);
    if (e.target) {
      if (field === 'quantity') {
        if (e.target.value) {
          const expn = /^[0-9]\d*$/;
          if (expn.test(e.target.value)) {
            fd[field] = e.target.value;
          }
        } else {
          fd[field] = 0;
        }
      } else if (field === 'acCode') {
        fd.acCodeId = '';
        fd[field] = e.target.value;
      } else {
        fd[field] = e.target.value;
      }
    }
    setServiceData(fd);
  };
  const onselectionchange = (e, label, name) => {
    const fd = cloneDeep(serviceData);
    fd[name] = e.value;
    fd[label] = e.label;
    setServiceData(fd);
  };
  useEffect(() => {
    if (serviceData.acCode && (serviceData.acCodeId === ZERO_UUID || !serviceData.acCodeId)) {
      setnewACCode(true);
    }
  }, []);

  return (
    <>
      <div className="modal-content" data-testid="add-service">
        <div className="row mb-4 label-space">
          <div className="col-4 ">
            <InputType1
              id="number"
              value={serviceData.number}
              onChange={(e) => handleChange(e, 'number')}
              className="modal-input"
              label="Service Line #"
              isMandatory="true"
              name="number"
              disabled
            />
          </div>
          <div className="col-8">
            <InputType1
              id="serviceLineName"
              name="serviceLineName"
              value={serviceData.serviceLineName}
              onChange={(e) => handleChange(e, 'serviceLineName')}
              className="modal-input"
              label="Service Line Name"
              isMandatory="true"
              disabled={projectData?.phase > 2}
            />
          </div>
        </div>
        <div className="row mb-3 ">
          {newACCode
            ? (
              <div className="col-4">
                <InputType1
                  id="acCode"
                  name="acCode"
                  maxLength="50"
                  value={serviceData.acCode ? serviceData.acCode : ''}
                  onChange={(e) => handleChange(e, 'acCode')}
                  className="modal-input"
                  label="AC code"
                />
              </div>
            )
            : (
              <div className="col-4">
                <label className="d-flex mb-2">
                  AC Code
                </label>
                <Select
                  className=""
                  placeholder="AC code"
                  options={acCodeList}
                  id="acCodeId"
                  classNamePrefix="react-tag"
                  value={acCodeList.filter((val) => val.value === serviceData.acCodeId)}
                  onChange={(e) => { onselectionchange(e, 'acCode', 'acCodeId'); }}
                  maxMenuHeight={fromSupplementary ? 150 : ''}
                  isDisabled={isVesselUser}
                  filterOption={(option, inputValue) => {
                    const { name, code } = option.data;
                    return (
                      name.toLowerCase().includes(inputValue.toLowerCase())
                      || code.toLowerCase().includes(inputValue.toLowerCase())
                    );
                  }}
                />
              </div>
            )}
          <div className="col-4">
            <InputType1
              id="quantity"
              name="quantity"
              maxLength="8"
              value={serviceData.quantity ? serviceData.quantity : ''}
              onChange={(e) => handleChange(e, 'quantity')}
              className="modal-input"
              label="Quantity"
              disabled={projectData?.phase > 2}
            />
          </div>
          <div className="col-4">
            <label className="d-flex mb-2">
              UOM
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              className=""
              placeholder="UOM"
              options={uomList}
              classNamePrefix="react-tag"
              id="uomId"
              value={uomList.filter((val) => val.value === serviceData.uomId)}
              onChange={(e) => { onselectionchange(e, 'uom', 'uomId'); }}
              maxMenuHeight={fromSupplementary ? 150 : ''}
              isDisabled={projectData?.phase > 2}
              filterOption={(option, inputValue) => {
                const { uomName } = option.data;
                return (
                  uomName.toLowerCase().includes(inputValue.toLowerCase())
                );
              }}
            />
          </div>
        </div>
        <div className="check-box">
          <input
            type="checkbox"
            id="newACCode"
            name="newACCode"
            checked={newACCode}
            disabled={isVesselUser}
            onChange={() => {
              if (!newACCode) {
                const temp = cloneDeep(serviceData);
                temp.acCodeId = ZERO_UUID;
                temp.acCode = '';
                setServiceData(temp);
              }
              setnewACCode(!newACCode);
            }}
          />
          <label htmlFor="newACCode">Input AC Code Manually</label>
        </div>
      </div>
    </>
  );
};
export default AddService;
