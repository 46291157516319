/* istanbul ignore file */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Outlet, useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import Header from './ProjectHead';
import Sidebar from './Sidebar';
import DmsContainer from './DMS';
import WohaPage from './WohaPage';
import PohaPage from './PohaPage';
import SubHead from './ProjectHead/subHead';
import VesselComponents from './VesselComponents';
import { getSpecById, specificationPdfExport, GetEmptySpecificationList, GetEmptyServiceLineList, ExportExcelForSpecLibrary } from './PhasePage/Specify/services';
import { getProjectDetailsTSI, updateProjectStatus, getProjectStatus } from './NewProject/services';
import ModalBox from '../../common/ModalBox';
import PreConfirm from './PreConfirm';
import showAlert from '../../../utils/alert';
import helper from '../../../utils/helper';
import Comments from './Comments';
import SpecifyApproval from './SpecifyApproval';
import { saveSpecVariation, VariationPreviewMail, variationPreviewSend, notificationCount } from './PhasePage/Tender/SupplementarySpecs/services';
import EmailPreview from './PhasePage/Repair/AddVariation/EmailPreview';
import ConfirmVariationModal from './PhasePage/Repair/AddVariation/ConfirmVariation';
import Variation from './PhasePage/Repair/AddVariation/variation';
import CloseJobs from './PhasePage/Repair/SpecComponents/closeJobs';
import { projectRepairClose, getProjectRepairData, lockSpec } from './PhasePage/Repair/services';
import DownloadConfirmModal from './PhasePage/Specify/downloadConfirmModal';
import PrivacyPolicy from '../../Policies/privacy';
import TermsOfUse from '../../Policies/TermsOfUse';
import SpecDownload from './SpecDownload';
import ExcelSpecModal from './SpecDownload/ExcelSpecModal';

const index = () => {
  const navigate = useNavigate();
  const params = qs.parse(window.location.search);
  const { addColor, roles, ZERO_UUID, formValidation } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = (
    (userRole === roles.AUTHOR)
    || (userRole === roles.EDITOR)
    || (userRole === roles?.ADMIN));
  const isMaster = userRole === roles.VESSEL_USER;
  const [openModal, setOpenModal] = useState('');
  const location = useLocation();
  const [reload, setReload] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [currencyId, setCurrencyId] = useState('');
  const [projInfo, setProjInfo] = useState({ phase: 0 });
  const [isTsiDisabled, setIsTsiDisabled] = useState(false);
  const [currency, setCurrency] = useState('');
  const [isShowImport, setIsShowImport] = useState(false);
  const [specDetails, setSpecDetails] = useState();
  const [canApprove, setCanApprove] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [reason, setReason] = useState('');
  const [projectStatus, setProjectStatus] = useState(0);
  const [showView, setShowView] = useState(false);
  const [isSupplementaryClicked, setIsSupplementaryClicked] = useState(false);
  const [isComponentClicked, setIsComponentClicked] = useState(false);
  const [addClicked, setIsAddClicked] = useState(false);
  const [isSubmited, setIsSubmitted] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [specList, setSpecList] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [specId, setSpecId] = useState(ZERO_UUID);
  const [sendForApproval, setSendForApproval] = useState(false);
  const [fromVariation, setFromVariation] = useState(false);
  const [showVariationPreview, setShowVariationPreview] = useState(false);
  const [showJobsList, setShowJobsList] = useState(false);
  const [showConfirmVariationModal, setShowConfirmVariationModal] = useState(false);
  const [previewContent, setEmailPreviewContent] = useState({});
  const [variationNotification, setVariationNotification] = useState();
  const [activeTab, setActiveTab] = useState(true);
  const [isDownload, setIsDownload] = useState(false);
  const [showServiceLineModal, setShowServiceLineModal] = useState(false);
  const [continueServ, setContinueServ] = useState(false);
  const [emptySpecListVariation, setEmptySpecListVariation] = useState();
  const [emptySpecList, setEmptySpecList] = useState([]);
  const [downloadConfirmModal, setDownloadConfirmModal] = useState('');
  const [projectRepair, setProjectRepair] = useState();
  const [closeSpecId, setCloseSpecId] = useState('');
  const [updatedServiceLine, setUpdatedServiceLine] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const [cLoading, setcLoading] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const [specToDownload, setSpecToDownload] = useState([]);
  const [disableBtn, setDisableBtn] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedWo, setIsCheckedWo] = useState(true);
  const [isCheckedTC, setIsCheckedTC] = useState(true);
  const [isCheckedSL, setIsCheckedSL] = useState(true);
  const [isCheckedVS, setIsCheckedVS] = useState(true);
  const [isCheckedQty, setIsCheckedQty] = useState(true);
  const [downloadWithWorkOrders, setDownloadWithWorkOrders] = useState(false);
  const [downloadWithPOOrders, setDownloadWithPOOrders] = useState(false);
  const [downloadWithTCOrders, setDownloadWithTCOrders] = useState(false);
  const [downloadWithSLOrders, setDownloadWithSLOrders] = useState(false);
  const [downloadWithVSOrders, setDownloadWithVSOrders] = useState(false);
  const [downloadWithQty, setDownloadWithQty] = useState(false);
  const [excelConfirmModal, setExcelConfirmModal] = useState(false);

  const exportExcel = (data) => {
    setIsDownload(true);
    ExportExcelForSpecLibrary(id, projInfo.name, data?.type)
      .then(() => {
        setIsDownload(false);
      });
  };

  const handleWorkOrdersCheckboxChange = () => {
    setDownloadWithWorkOrders(!downloadWithWorkOrders);
    setIsCheckedWo(!isCheckedWo);
  };

  const handlePoCheckboxChange = () => {
    setDownloadWithPOOrders(!downloadWithPOOrders);
    setIsChecked(!isChecked);
  };

  const handleTCCheckboxChange = () => {
    setDownloadWithTCOrders(!downloadWithTCOrders);
    setIsCheckedTC(!isCheckedTC);
  };

  const handleSLCheckboxChange = () => {
    setDownloadWithSLOrders(!downloadWithSLOrders);
    setIsCheckedSL(!isCheckedSL);
  };

  const handleVSCheckboxChange = () => {
    setDownloadWithVSOrders(!downloadWithVSOrders);
    setIsCheckedVS(!isCheckedVS);
  };

  const handleQtyCheckboxChange = () => {
    setDownloadWithQty(!downloadWithQty);
    setIsCheckedQty(!isCheckedQty);
  };
  const setDownloadChecked = () => {
    setDownloadWithWorkOrders(false);
    setDownloadWithPOOrders(false);
    setDownloadWithTCOrders(false);
    setDownloadWithSLOrders(false);
    setDownloadWithVSOrders(false);
    setDownloadWithQty(false);
    setIsCheckedWo(true);
    setIsChecked(true);
    setIsCheckedTC(true);
    setIsCheckedSL(true);
    setIsCheckedVS(true);
    setIsCheckedQty(true);
  };

  const handleContinueInDownload = () => {
    const payload = {
      projectId: id,
      specIds: specToDownload,
      isPoNotNeeded: downloadWithPOOrders,
      isWoNotNeeded: downloadWithWorkOrders,
      isTermsAndConditionsNotNeeded: downloadWithTCOrders,
      isServiceLineNotNeeded: downloadWithSLOrders,
      isVesselAssignedSpecNotNeeded: downloadWithVSOrders,
      IsQuantityNeeded: !downloadWithQty
    };
    setIsDownload(true);
    setDownloadConfirmModal(false);
    specificationPdfExport(payload).then((resp) => {
      showAlert(`${resp.data}`, 'success');
      setIsDownload(false);
      setSpecToDownload([]);
    });
  };
  const initialFormData = {
    sectionId: '',
    sectionNo: '',
    sectionTitle: '',
    sectionType: 0,
    specId: '',
    specNoAutoGenerated: '',
    specTitle: '',
    specType: 0,
    projectId: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const onCloseWindow = () => {
    setOpenModal('');
    setReload(!reload);
  };

  const handleOpenModal = (val) => {
    setOpenModal(val);
  };

  const onReload = () => {
    setReload(!reload);
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const emptySpecificationListVariation = () => {
    GetEmptyServiceLineList({ projectId: id, type: 5 }).then((res) => {
      if (res.success) {
        setLoading(false);
        setEmptySpecListVariation(res.data);
      }
    });
    GetEmptySpecificationList({ projectId: id, type: 5 }).then((res) => {
      if (res.success) {
        setLoading(false);
        setEmptySpecList(res.data);
      }
    });
  };

  const getProjDetails = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      if (res?.success) {
        setDisableBtn(res?.data);
        setLoading(false);
        const data = res?.data;
        setCurrency(res?.data?.currencySymbol);
        setCurrencyId(res?.data?.currencyId);
        data.phase = res?.data?.phase || 0;
        setProjInfo(data);
        let disabled = true;
        if (isTSI) {
          if (data?.phase === 2
            && (data?.specApprovalStatus === 0 || data?.specApprovalStatus === 3)) {
            disabled = false;
          } else if (data?.phase === 3) {
            disabled = !data.isWohaMappingEditable;
          } else {
            disabled = true;
          }
        } else {
          disabled = true;
        }
        if (localStorage.getItem('USER_ID') === data?.backupTsiId) {
          disabled = true;
        }
        setIsTsiDisabled(disabled);
        if (isTSI) {
          if (localStorage.getItem('USER_ID') === data?.backupTsiId) {
            setIsShowImport(false);
          } else {
            setIsShowImport(true);
          }
        } else {
          setIsShowImport(false);
        }
      }
    });
    let type = '';
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectStatus(id, type).then((res) => {
      if (res?.success) {
        setLoading(false);
        setProjectStatus(res?.data);
        if (res.data && res.data !== 0) {
          setCanApprove(true);
        } else {
          setCanApprove(false);
        }
      }
    });
  };

  const notificationVariation = () => {
    notificationCount(id).then((res) => {
      setVariationNotification(res.data);
    });
  };

  const showVariation = () => {
    setShowView(true);
    setActiveTab(true);
    setFromVariation(true);
  };

  const onClose = () => {
    setShowView(false);
    setIsComponentClicked(false);
    setIsSupplementaryClicked(false);
    notificationVariation();
    setActiveTab(true);
    setShowServiceLineModal(false);
  };

  const downloadExcel = () => {
    setDownloadChecked();
    setDownloadConfirmModal(true);
  };

  useEffect(() => {
    getProjDetails();
  }, [location]);

  const handleValidation = (val) => {
    let isValid = false;
    const v = String(val)?.trim();
    if (!v || v === null || v === '' || v === ' ') {
      isValid = false;
      addColor('reason', 'add');
    } else {
      isValid = true;
      addColor('reason', '');
    }
    return isValid;
  };

  const confirmModal = () => {
    let message = '';
    let status = '';
    let isValid = false;
    let type = '';

    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (isOpened === 'reject') {
      message = 'Successfully Rejected';
      status = 4;
      if (handleValidation(reason)) {
        isValid = true;
      }
    }
    if (isOpened === 'revision') {
      message = 'Successfully Submitted for Revision';
      status = 3;
      if (handleValidation(reason)) {
        isValid = true;
      }
    }
    if (isOpened === 'approve') {
      isValid = true;
      message = 'Successfully Approved';
      status = 2;
    }
    const paylaod = {
      projectId: id,
      type,
      level: projectStatus,
      status,
      comment: reason
    };
    if (isValid) {
      setcLoading(true);
      updateProjectStatus(paylaod).then((resp) => {
        setcLoading(false);
        if (resp?.success) {
          showAlert(message, 'success');
          navigate('/user/projects');
          getSpecById({ id, type: 1 }).then(() => {
            //
          });
          getSpecById({ id, type: 2 }).then(() => {
            //
          });
          setIsOpened(false);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const backtoList = () => {
    setIsSupplementaryClicked(false);
    emptySpecificationListVariation();
  };

  const saveForm = () => {
    formData.projectId = id;
    formData.specId = specId || ZERO_UUID;
    if (formValidation(['sectionId', 'specTitle'], formData)) {
      saveSpecVariation(formData).then((res) => {
        if (res.success) {
          setSpecId(res?.data?.id);
          setIsSaved(true);
          setSpecDetails(res?.data);
          if (addClicked) {
            showAlert('Successfully Saved', 'success');
          } else {
            showAlert('Successfully Updated', 'success');
          }
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  useEffect(() => {
    if (location?.pathname.includes('/repair')) {
      setFromVariation(true);
    } else {
      setFromVariation(false);
    }
  });

  const handleVariationPreview = () => {
    if (continueServ === true || (emptySpecListVariation?.length === 0
      && emptySpecList?.length === 0)) {
      VariationPreviewMail(id).then((res) => {
        if (res.success) {
          setShowVariationPreview(true);
          setEmailPreviewContent(res.data);
        }
      });
    } else {
      setShowServiceLineModal(true);
    }
  };

  useEffect(() => {
    emptySpecificationListVariation();
    setLoading(true);
  }, []);

  const handleConfirmVariation = () => {
    variationPreviewSend({ projectId: id }).then((res) => {
      if (res.success) {
        setShowConfirmVariationModal(true);
        setInitialV(res.data);
      }
    });
  };

  const handleCloseAll = () => {
    setShowConfirmVariationModal(false);
    setShowVariationPreview(false);
    setShowView(false);
  };

  const onCloseRepair = () => {
    projectRepairClose({ id }).then((rep) => {
      if (rep.success) {
        setShowJobsList(false);
        showAlert('Successfully closed Repair phase', 'success');
        navigate(`/user/project/${id}/close?vesselId=${params.vesselId}`);
      }
    });
  };

  useEffect(() => {
    if (location?.pathname.includes('/report')) {
      getProjectRepairData(id).then((res) => {
        if (res.data && res.data.repairStartDate && res.data.repairStartDate !== '01-01-0001 00:00:00') {
          setProjectRepair(res.data.repairStartDate);
        } else {
          setProjectRepair('');
        }
      });
    }
    if (location?.pathname.includes('/close')) {
      setProjectRepair('closePhase');
    }
  }, [location]);

  const onSelectRepair = (selectedSpecId) => {
    setCloseSpecId(selectedSpecId);
    setShowJobsList(false);
  };

  const reloadServiceLine = () => {
    setUpdatedServiceLine(Math.random());
  };

  const setActivateSL = (data, type) => {
    navigate(`/user/project/${id}/specify?vesselId=${params.vesselId}`, { state: { specDataURL: data, specTypeURL: type } });
  };

  const lockApiCall = () => {
    lockSpec(id).then((res) => {
      getProjDetails();
      setLockModal(false);
    });
  };

  const getMarginRight = () => {
    if (location.pathname.includes('/specify')) {
      return '40px';
    } if (location.pathname.includes('/repair')) {
      if (projectRepair) {
        return '300px';
      }
      return '-100px';
    }

    return '90px';
  };

  const getMarginRightTSI = () => {
    if (location.pathname.includes('/specify')) {
      if (canApprove) {
        return '200px';
      }
      return '200px';
    } if (location.pathname.includes('/repair')) {
      if (projectRepair) {
        return '300px';
      }
      return '-100px';
    }
    if (location.pathname.includes('/plan') && canApprove) {
      return '190px';
    }

    return '494px';
  };

  return (
    <>
      <div className="page-wrapper tsi-user" data-testid="projectlist">
        <div className="header">
          <Header />
          <SubHead
            location={location}
            id={id}
            phase={projInfo?.phase}
            isTSI={isTSI}
            handleSidebarToggle={handleSidebarToggle}
            tenderStatus={projInfo?.tenderApprovalStatus}
          />
        </div>
        {openModal === 'dms' ? <DmsContainer projInfo={projInfo} isTSI={isTSI} onCloseWindow={onCloseWindow} openModal={openModal} isTsiDisabled={isTsiDisabled} /> : <></>}
        {openModal === 'woha' ? (
          <WohaPage
            onCloseWindow={onCloseWindow}
            openModal={openModal}
            onReload={onReload}
            isTsiDisabled={isTsiDisabled}
            isTSI={isTSI}
            isShowImport={isShowImport}
          />
        ) : (
          <></>
        )}
        {openModal === 'poha' ? (
          <PohaPage
            onCloseWindow={onCloseWindow}
            openModal={openModal}
            onReload={onReload}
            currency={currency}
            isTSI={isTSI}
            isTsiDisabled={isTsiDisabled}
            isShowImport={isShowImport}
            projInfo={projInfo}
          />
        ) : (
          <></>
        )}
        {openModal === 'components' ? <VesselComponents onCloseWindow={onCloseWindow} openModal={openModal} /> : <></>}
        {openModal === 'specify-download' ? <SpecDownload onCloseWindow={onCloseWindow} openModal={openModal} /> : <></>}
        {!location.pathname.includes('compare-quotes')
          && (
            <Sidebar
              phase={projInfo?.phase}
              projInfo={projInfo}
              setOpenModal={handleOpenModal}
              openModal={openModal}
              reload={reload}
              isTsiDisabled={isTsiDisabled}
              isTSI={isTSI}
              isMaster={isMaster}
              projectRepair={projectRepair}
              isSidebarOpen={isSidebarOpen}
              disableBtn={disableBtn}
            />
          )}
        <Outlet context={{
          reload: [reload],
          specs: [specDetails, setSpecDetails],
          projInfo,
          getProjDetails,
          showView,
          repairStart: [projectRepair, setProjectRepair],
          closeSpec: [closeSpecId, setCloseSpecId],
          updatedServiceLine
        }}
        />

      </div>
      {!location?.pathname.includes('/tender') && !location?.pathname.includes('/paint-cost') && !location?.pathname.includes('/close') && !location?.pathname.includes('/report') && !location?.pathname.includes('/task') && !location?.pathname.includes('/sp-download') && !isMaster ? (
        <div className={isMaster && location?.pathname.includes('/repair') ? '' : 'fixed-footer card d-flex justify-content-between'}>
          {!location?.pathname.includes('/repair') ? <Comments id={id} appendClassName="comments-btn" locationPhase={location} /> : <div />}
          {location?.pathname.includes('/repair') && (
            <button
              type="button"
              className="primary-btn large me-auto ms-12"
              id="spec-download"
              disabled={isDownload}
              onClick={() => {
                setExcelConfirmModal(true);
              }}
            >
              Download Specs (Excel)
            </button>
          )}
          {userRole === roles?.ADMIN ? (

            <div className="text-black font-12 footer-specify" style={{ marginRight: getMarginRight() }}>
              <div>{`© ${new Date().getFullYear()} ThinkPalm`}</div>

            </div>

          ) : (

            <div className="text-black font-12 footer-tender" style={{ marginRight: getMarginRightTSI() }}>
              <div className=" ms-5 ps-5">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

            </div>

          )}
          <div className="d-flex">
            {location?.pathname.includes('/specify') && (
              <div className="d-flex">
                <button
                  type="button"
                  className="secondary-btn large me-2"
                  id="spec-download"
                  data-tooltip-id="project-rfqData"
                  data-tooltip-content="Download specs to export to sister projects."
                  onClick={() => {
                    setExcelConfirmModal(true);
                  }}
                >
                  Download Specs (Excel)
                </button>
                <Tooltip id="project-rfqData" place="top" className="tooltip  mt-3" />
                <button
                  type="button"
                  className="secondary-btn large mr-10"
                  id="spec-download"
                  disabled={isDownload}
                  onClick={() => {
                    downloadExcel();
                  }}
                >
                  Download Specs (Pdf)
                </button>
              </div>
            )}
            {excelConfirmModal && (
              <ExcelSpecModal
                setExcelConfirmModal={setExcelConfirmModal}
                exportExcel={exportExcel}
              />
            )}
            {projectRepair && !isMaster && location?.pathname.includes('/repair') && !location?.pathname.includes('/projcost') && (
              <>
                <div className="relative">
                  <button
                    type="button"
                    className="secondary-btn large mr-10"
                    onClick={() => {
                      showVariation();
                    }}
                  >
                    Add Variation
                  </button>
                  {variationNotification?.count > 0 ? <span className="dot-indicator-variation" /> : ''}
                </div>
                <button
                  type="button"
                  className="primary-btn large mr-10"
                  onClick={() => { setShowJobsList(true); }}
                  disabled={projInfo.phase !== 4 || !isTSI}
                >
                  Close Repair Phase
                </button>

              </>
            )}
            {isMaster ? '' : (
              <>
                {location?.pathname.includes('/specify') && isTSI && userRole !== roles?.ADMIN && (projInfo?.specApprovalStatus === 0 || projInfo?.specApprovalStatus === 3) ? (
                  <SpecifyApproval
                    projInfo={projInfo}
                    projectId={id}
                    reloadServiceLine={reloadServiceLine}
                    setActivateSL={setActivateSL}
                  />
                ) : (
                  ''
                )}
              </>
            )}

            {!isMaster && canApprove && (
              <>
                <button
                  type="button"
                  className="secondary-btn large mr-10"
                  id="reject"
                  onClick={() => {
                    setReason('');
                    setIsOpened('reject');
                  }}
                >
                  Reject
                </button>
                <button
                  type="button"
                  className="secondary-btn large mr-10"
                  id="revision"
                  onClick={() => {
                    setReason('');
                    setIsOpened('revision');
                  }}
                >
                  Submit for revision
                </button>
                <button
                  type="button"
                  className="primary-btn large mr-10"
                  id="approve"
                  onClick={() => {
                    setReason('');
                    setIsOpened('approve');
                  }}
                >
                  Approve
                </button>
              </>
            )}
          </div>

          {isOpened ? (
            <ModalBox isCloseModal={false} modalClassName="medium modal-scroll">
              <PreConfirm
                name={projInfo?.name}
                nameTooltip={projInfo?.nameTooltip}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                setReason={setReason}
                reason={reason}
                confirmModal={confirmModal}
                loading={loading}
                cLoading={cLoading}
              />
            </ModalBox>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div />
      )}
      <Variation
        onBack={backtoList}
        isComponentClicked={isComponentClicked}
        title={isSupplementaryClicked ? (addClicked ? 'Add Variation' : 'Edit Variations') : 'Variation'}
        onClose={onClose}
        showView={showView}
        setShowView={setShowView}
        setIsComponentClicked={setIsComponentClicked}
        specDetails={specDetails}
        setSpecDetails={setSpecDetails}
        saveForm={saveForm}
        formData={formData}
        setFormData={setFormData}
        setIsSaved={setIsSaved}
        currencyId={currencyId}
        isSaved={isSaved}
        specId={specId}
        setIsAddClicked={setIsAddClicked}
        addClicked={addClicked}
        fromVariation={fromVariation}
        setIsSupplementaryClicked={setIsSupplementaryClicked}
        initialFormData={initialFormData}
        setSpecId={setSpecId}
        specList={specList}
        isSubmited={isSubmited}
        setButtonDisable={setButtonDisable}
        setSpecList={setSpecList}
        buttonDisable={buttonDisable}
        sendForApproval={sendForApproval}
        setSendForApproval={setSendForApproval}
        projInfo={projInfo}
        isSupplementaryClicked={isSupplementaryClicked}
        handleVariationPreview={handleVariationPreview}
        loading={loading}
        projectId={id}
        isTsiDisabled={isTsiDisabled}
        currency={currency}
        isTSI={isTSI}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setShowServiceLineModal={setShowServiceLineModal}
        showServiceLineModal={showServiceLineModal}
        setContinueServ={setContinueServ}
        emptySpecificationList={() => emptySpecificationListVariation()}
        emptySpecListVariation={emptySpecListVariation}
        emptySpecList={emptySpecList}
        setShowVariationPreview={setShowVariationPreview}
      />
      {showVariationPreview && (
        <EmailPreview
          showVariationPreview={showVariationPreview}
          setShowVariationPreview={setShowVariationPreview}
          handleConfirmVariation={handleConfirmVariation}
          previewContent={previewContent}
        />
      )}
      {showConfirmVariationModal
        && <ConfirmVariationModal isCloseModal={false} handleCloseAll={handleCloseAll} />}
      {showJobsList && (
        <CloseJobs
          setShowJobsList={setShowJobsList}
          onCloseRepair={onCloseRepair}
          onSelectRepair={onSelectRepair}
        />
      )}
      {downloadConfirmModal && (
        <DownloadConfirmModal
          setDownloadConfirmModal={setDownloadConfirmModal}
          handleContinueInDownload={handleContinueInDownload}
          isDownload={isDownload}
          handleWorkOrdersCheckboxChange={handleWorkOrdersCheckboxChange}
          handlePoCheckboxChange={handlePoCheckboxChange}
          handleTCCheckboxChange={handleTCCheckboxChange}
          handleSLCheckboxChange={handleSLCheckboxChange}
          handleVSCheckboxChange={handleVSCheckboxChange}
          handleQtyCheckboxChange={handleQtyCheckboxChange}
          isChecked={isChecked}
          isCheckedWo={isCheckedWo}
          isCheckedTC={isCheckedTC}
          isCheckedSL={isCheckedSL}
          isCheckedVS={isCheckedVS}
          isCheckedQty={isCheckedQty}
        />
      )}
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
      {lockModal ? (
        <ModalBox
          openDeleteModal={lockModal}
          modalClassName="medium"
          heading={projInfo?.isSpecLocked ? 'Unlock Specs' : 'Lock Specs'}
          onSave={() => lockApiCall()}
          closeModal={() => setLockModal(false)}
          buttonName={projInfo?.isSpecLocked ? 'Unlock' : 'Lock'}
        >
          {projInfo?.isSpecLocked
            ? (
              <div>
                On clicking Unlock Specs, create and edit permission for vessel
                users mapped to the project will be enabled. Do you want to continue?
              </div>
            )
            : (
              <div>
                On clicking Lock Specs, create and edit permission for vessel
                users mapped to the project will be disabled. Do you want to continue?
              </div>
            )}
        </ModalBox>
      ) : (
        ''
      )}
    </>
  );
};
export default index;
